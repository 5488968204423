<sd-scandata-tree
  [data]="scandata()"
  [currentStation]="currentStation()"
  [cameraProjection]="cameraProjection()!"
  [quotaExceeded]="quotaExceeded()"
  (modelIconClick)="modelIconClick($event)"
  (modelNameClick)="modelNameClick($event)"
  (modelExpandClick)="toggleExpand($event)"
  (modelDetailClick)="modelDetailClicked($event)"
  (modelFitToViewClick)="modelFitToViewClicked($event)"
  (stationIconClick)="stationIconClick($event)"
  (stationNameClick)="stationNameClick($event)"
></sd-scandata-tree>
