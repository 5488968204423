@for (item of data(); track item.scan.id) {
  <div>
    <div class="flex flex-row min-h-[32px] text-sm cursor-pointer hover:bg-gray-0">
      <sd-scandata-tree-icon
        class="flex items-center cursor-default pl-[10px] min-w-[38px] max-w-[38px]"
        [treeIcon]="getModelIcon(item.scan)"
        [disabled]="loadModelDisabled(item.scan)"
        (clicked)="modelIconClicked(item.scan)"
      ></sd-scandata-tree-icon>
      <div
        modus-tooltip="{{ item.scan.name }}"
        class="flex items-center w-full overflow-hidden p-1"
        (click)="modelNameClicked(item.scan)"
      >
        <div
          class="block w-full overflow-hidden text-ellipsis whitespace-nowrap"
          [ngClass]="{
            'text-red': modelHasError(item.scan),
            'text-gray-6': modelNotReady(item.scan),
          }"
        >
          {{ item.scan.name }}
          <div class="text-small italic text-gray-6">{{ getModelInfoMessage(item.scan) }}</div>
        </div>

        @if (item.version) {
          <modus-badge class="ml-2 w-10 text-center" type="counter" color="tertiary">{{
            item.version
          }}</modus-badge>
        }
      </div>

      <div class="flex flex-row items-center">
        @if (item.scan.numberOfStations > 0 && item.scan.expanded) {
          <md-icon class="!flex text-lg/5 h-full items-center" (click)="toggleExpand(item.scan)"
            >caret_down</md-icon
          >
        } @else if (item.scan.numberOfStations > 0 && !item.scan.expanded) {
          <md-icon class="!flex text-lg/5 h-full items-center" (click)="toggleExpand(item.scan)"
            >caret_right</md-icon
          >
        } @else {
          <span class="w-6"></span>
        }
        <sd-scandata-tree-menu
          [scan]="item.scan"
          [fitToViewDisabled]="fitToViewDisabled(item.scan)"
          (modelDetailClick)="modelDetailClicked(item.scan)"
          (modelFitToViewClick)="modelFitToViewClicked(item.scan)"
        ></sd-scandata-tree-menu>
      </div>
    </div>
    @if (item.scan.numberOfStations > 0 && item.scan.expanded) {
      <div class="cursor-pointer pl-8">
        @if (!item.scan.stations && stationsLoading(item.scan)) {
          <div class="text-small italic py-1 pl-2">Loading...</div>
        }
        @for (station of item.scan.stations; track station.id) {
          <div class="flex flex-row min-h-[32px] pl-1 items-center text-sm hover:bg-gray-0">
            <sd-scandata-tree-icon
              modus-tooltip="{{ stationDisabledReason() }}"
              tooltipDisabled="{{ stationTooltipDisabled() }}"
              class="min-w-[28px] max-w-[28px]"
              [treeIcon]="getStationIcon(station)"
              [disabled]="loadStationDisabled()"
              (clicked)="stationIconClicked(station)"
            ></sd-scandata-tree-icon>
            <div
              modus-tooltip="{{ 'Station: ' + station.name }}"
              class="w-full overflow-hidden pl-1 py-1 pr-8"
              (click)="stationNameClicked(station)"
            >
              <div
                class="w-full overflow-hidden text-ellipsis whitespace-nowrap"
                [ngClass]="{ 'text-red': stationHasError(station) }"
              >
                {{ 'Station: ' + station.name }}
              </div>
              <div class="w-full overflow-hidden text-ellipsis whitespace-nowrap text-small italic">
                {{ getStationErrorMessage(station) }}
              </div>
            </div>
          </div>
        }
      </div>
    }
  </div>
}
