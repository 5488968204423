import { isNil, isNumeric } from '@trimble-gcs/common';
import { ScandataModel } from './scandata.models';

export function sortScandata<T extends ScandataModel>(
  data: T[],
  sortBy: keyof ScandataModel,
  sortDirection: 'asc' | 'desc' | '',
): T[] {
  /**
   * We want to ensure sorting on a column with duplicate values will always
   * return the items in the same sort order.
   *
   * To achieve that we need to sort on a second distinct value, in this case id.
   *
   * This method is copied from the material library and modified to include the second sort.
   *
   * Source:
   * https://github.com/angular/components/blob/main/src/material/table/table-data-source.ts#L173
   */

  return data.toSorted((scanA, scanB) => {
    let valueA = sortingDataAccessor()(scanA, sortBy);
    let valueB = sortingDataAccessor()(scanB, sortBy);

    // If there are data in the column that can be converted to a number,
    // it must be ensured that the rest of the data
    // is of the same type so as not to order incorrectly.
    const valueAType = typeof valueA;
    const valueBType = typeof valueB;

    if (valueAType !== valueBType) {
      if (valueAType === 'number') {
        valueA += '';
      }
      if (valueBType === 'number') {
        valueB += '';
      }
    }

    // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
    // one value exists while the other doesn't. In this case, existing value should come last.
    // This avoids inconsistent results when comparing values to undefined/null.
    // If neither value exists, return 0 (equal).
    let comparatorResult = 0;
    if (valueA != null && valueB != null) {
      // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
      if (valueA > valueB) {
        comparatorResult = 1;
      } else if (valueA < valueB) {
        comparatorResult = -1;
      }
    } else if (valueA != null) {
      comparatorResult = 1;
    } else if (valueB != null) {
      comparatorResult = -1;
    }

    const distinctSort: keyof ScandataModel = 'id';
    let distinctValueA = sortingDataAccessor()(scanA, distinctSort).toString();
    let distinctValueB = sortingDataAccessor()(scanB, distinctSort).toString();

    return (
      comparatorResult * (sortDirection == 'asc' ? 1 : -1) ||
      distinctValueA.localeCompare(distinctValueB)
    );
  });
}

function sortingDataAccessor(): (scan: ScandataModel, sortBy: string) => string | number {
  return (scan: ScandataModel, sortBy: string): string | number => {
    const value: string = (scan as never)[sortBy];
    return isNumeric(value) ? Number(value) : isNil(value) ? '' : value.toLowerCase();
  };
}
