import { FileDownload, ScanDownload } from './download.models';

export class AddToDownload {
  static readonly type = '[Downloads] AddToDownload';
  constructor(
    public scanDownload: ScanDownload,
    public fileDownload: FileDownload,
  ) {}
}

export class PatchFileDownload {
  static readonly type = '[Downloads] PatchFileDownload';
  constructor(public fileDownload: FileDownload) {}
}

export class ClearAllDownloads {
  static readonly type = '[Downloads] ClearAllDownloads';
}

export class ClearCompletedDownloads {
  static readonly type = '[Downloads] ClearCompletedDownloads';
}
