import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { filter, switchMap } from 'rxjs';
import { AuthService } from '../auth.service';
import { AuthState } from '../auth.state';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, ModusButtonModule, MatProgressSpinnerModule],
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoutComponent implements OnInit {
  private store = inject(Store);
  private authService = inject(AuthService);

  loggedIn = this.store.selectSignal(AuthState.loggedIn);

  ngOnInit() {
    this.store
      .select(AuthState.loggedIn)
      .pipe(
        filter((loggedIn) => loggedIn),
        switchMap(() => this.authService.signOut()),
        untilDestroyed(this),
      )
      .subscribe();
  }

  login() {
    this.authService.signIn().pipe(untilDestroyed(this)).subscribe();
  }
}
