import { inject, Injectable } from '@angular/core';
import { Selector, State, StateContext, Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { filter } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../../../app-state/app.state';
import { ConnectRegion } from '../../../connect/connect.models';

export interface ProjectSelectStateModel {
  cachedConnectRegion?: ConnectRegion;
  cachedProject?: ConnectProject;
}

@State<ProjectSelectStateModel>({
  name: 'projectSelectState',
})
@Injectable()
export class ProjectSelectState {
  private store = inject(Store);

  @Selector() static cachedConnectRegion(
    state: ProjectSelectStateModel,
  ): ConnectRegion | undefined {
    return state.cachedConnectRegion;
  }

  @Selector() static cachedProject(state: ProjectSelectStateModel): ConnectProject | undefined {
    return state.cachedProject;
  }

  ngxsAfterBootstrap(ctx: StateContext<ProjectSelectStateModel>): void {
    this.store
      .select(AppState.connectRegion)
      .pipe(filter(isDefined))
      .subscribe((connectRegion) => ctx.patchState({ cachedConnectRegion: connectRegion }));

    this.store
      .select(AppState.project)
      .pipe(filter(isDefined))
      .subscribe((project) => ctx.patchState({ cachedProject: project }));
  }
}
