import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewChild, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Feature } from 'geojson';
import { ErrorState } from '../error-handling/error.state';
import { injectLogger } from '../logging/logger-injection';
import { ScandataEmptyReason } from '../scandata-list/scandata-empty/scandata-empty.component';
import { SortInfo } from '../scandata/scandata-query.models';
import {
  PatchScandataModel,
  SelectOnly,
  SelectScandataModel,
  SetSelected,
  SetSortInfo,
  UnselectScandataModel,
} from '../scandata/scandata.actions';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import { SetActiveBaseLayer } from './base-layer/base-layer.actions';
import { BaseLayer } from './base-layer/base-layer.models';
import { BaseLayerService } from './base-layer/base-layer.service';
import { BaseLayerState } from './base-layer/base-layer.state';
import { FeatureLayerService } from './feature-layer/feature-layer.service';
import { FeatureLayerState } from './feature-layer/feature-layer.state';
import { MapListComponent } from './map-list/map-list.component';
import { MapToolbarComponent } from './map-toolbar/map-toolbar.component';
import { FeatureClickedEvent, MapViewerComponent } from './map-viewer/map-viewer.component';
import { SetActiveMapTool, SetBounds, SetMapFilterOption } from './map.actions';
import { DEFAULT_BOUNDS_PADDING, MapBounds, MapFilterOption } from './map.models';
import { MapService } from './map.service';
import { MapState, MapTool } from './map.state';
import { calculateBounds } from './map.util';

@UntilDestroy()
@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MapViewerComponent, MapListComponent, MapToolbarComponent],
  templateUrl: './map.component.html',
  styles: [],
})
export class MapComponent {
  private logger = injectLogger('MapComponent');

  @ViewChild('mapViewer') private mapViewer!: MapViewerComponent;

  // loading
  private scandataLoading = this.store.selectSignal(ScandataState.isLoading);
  private featuresLoading = this.store.selectSignal(FeatureLayerState.isLoading);
  isLoading = computed(() => this.scandataLoading() || this.featuresLoading());
  scanLoadError = this.store.selectSignal(ErrorState.hasError('scanLoadError'));

  // sort
  sortInfo = this.store.selectSignal(ScandataState.sortInfo);

  // data
  mapScandata = toSignal(this.mapService.getMapScandata(), { initialValue: [] });

  // selected
  lastSelectedId = computed(() => {
    return this.store.selectSignal(ScandataState.chronologicalSelected)().at(-1)?.id;
  });

  // filter
  private filterCount = this.store.selectSignal(ScandataState.filterCount);
  private scandata = this.store.selectSignal(ScandataState.scandata);
  private textFilter = this.store.selectSignal(ScandataState.textFilter);
  filterOption = this.store.selectSignal(MapState.filterOption);

  // no data
  showScandataEmpty = computed(() => {
    return this.isLoading() || this.scanLoadError() ? false : this.mapScandata().length === 0;
  });

  scandataEmptyReason = this.getScandataEmptyReason();

  // layers
  baseLayers = toSignal(this.baseLayerService.getBaseLayers(), { initialValue: [] });
  featureLayer = toSignal(this.featureLayerService.getFeatureLayer(), { initialValue: null });
  activeBaseLayer = this.store.selectSignal(BaseLayerState.activeBaseLayer);

  bounds = this.store.selectSignal(MapState.bounds);
  activeMapTool = this.store.selectSignal(MapState.activeMapTool);

  constructor(
    private mapService: MapService,
    private featureLayerService: FeatureLayerService,
    private baseLayerService: BaseLayerService,
    private store: Store,
  ) {}

  featureClicked(event: FeatureClickedEvent) {
    const id = event.feature.id as string | undefined; //all our features have string ids;

    if (!id) {
      this.logger.error('Feature has no id', event.feature);
      return;
    }

    if (event.hasModifierKey) {
      if (event.feature.properties?.['selected']) {
        this.store.dispatch(new UnselectScandataModel(id));
      } else {
        this.store.dispatch(new SelectScandataModel(id));
      }
    } else {
      const selected = this.store.selectSnapshot(ScandataState.selected);
      if (selected.length === 1 && selected[0].id === id) {
        this.store.dispatch(new UnselectScandataModel(id));
      } else {
        this.store.dispatch(new SelectOnly([id]));
      }
    }
  }

  featureDblClicked(event: FeatureClickedEvent) {
    const feature = event.feature;
    const bounds: MapBounds = {
      bbox: calculateBounds(feature),
      padding: DEFAULT_BOUNDS_PADDING,
    };
    this.store.dispatch(new SetBounds(bounds));
  }

  featuresSelected(features: Feature[]) {
    const selectScanIds = this.mapScandata()
      .filter((scan) => !scan.hiddenOnMap)
      .filter((scan) => features.some((feature) => feature.id === scan.id))
      .map((scan) => scan.id);
    this.store.dispatch(new SelectOnly(selectScanIds));
  }

  changeItemVisibilityClicked(model: ScandataModel) {
    model.hiddenOnMap = !model.hiddenOnMap;
    this.store.dispatch(new PatchScandataModel(model));
  }

  selectionChange(scans: ScandataModel[]) {
    this.store.dispatch(new SetSelected(scans));
  }

  zoomToItemClicked(model: ScandataModel) {
    const featureCollection = this.store.selectSnapshot(FeatureLayerState.featureCollection);
    const feature = featureCollection?.features.find((ftr) => ftr.id === model.id);
    if (feature) {
      const bounds: MapBounds = {
        bbox: calculateBounds(feature),
        padding: DEFAULT_BOUNDS_PADDING,
      };
      this.store.dispatch(new SetBounds(bounds));
    }
  }

  mapToolSelected(mapTool: MapTool) {
    this.store.dispatch(new SetActiveMapTool(mapTool));
  }

  zoomIn() {
    this.mapViewer.zoomIn();
  }

  zoomOut() {
    this.mapViewer.zoomOut();
  }

  fitToView() {
    const featureCollection = this.store.selectSnapshot(FeatureLayerState.featureCollection);
    const bounds: MapBounds = {
      bbox: this.featureLayerService.getFeatureCollectionOrDefaultBBox(featureCollection),
      padding: DEFAULT_BOUNDS_PADDING,
    };

    this.store.dispatch(new SetBounds(bounds));
  }

  filterOptionChanged(option: MapFilterOption) {
    this.store.dispatch(new SetMapFilterOption(option));
  }

  sortChanged(sortInfo: SortInfo) {
    this.store.dispatch(new SetSortInfo(sortInfo));
  }

  setActiveBaseLayer(layer: BaseLayer) {
    this.store.dispatch(new SetActiveBaseLayer(layer));
  }

  boundsChanged(mapBounds: MapBounds) {
    this.store.dispatch(new SetBounds(mapBounds));
  }

  private getScandataEmptyReason() {
    return computed(() => {
      if (this.filterCount() === 0 && this.scandata().length === 0) {
        return ScandataEmptyReason.NoUploads;
      }

      if (this.filterCount() > 0 || isDefined(this.textFilter())) {
        return ScandataEmptyReason.NoFilterResults;
      }

      if (this.filterOption() === MapFilterOption.LocatedOnly) {
        return ScandataEmptyReason.NoGeolocated;
      }

      return ScandataEmptyReason.NoUploads;
    });
  }
}
