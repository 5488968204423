import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { AppState } from '../app-state/app.state';
import { ClientHeaders, IngestionSource } from './client-header.models';

@Injectable({
  providedIn: 'root',
})
export class ClientHeaderService {
  constructor(private store: Store) {}

  getClientHeaders(source?: IngestionSource): {
    [header: string]: string;
  } {
    const appSettings = this.store.selectSnapshot(AppState.settings);
    const clientName = isDefined(source) ? `${appSettings.title} - ${source}` : appSettings.title;
    return this.createClientHeaders(clientName);
  }

  private createClientHeaders(clientName: string): ClientHeaders {
    return {
      'Trimble-FieldSystems-ClientName': clientName,
      'Trimble-FieldSystems-ClientVersion': 'latest',
    };
  }
}
