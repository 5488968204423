import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { filter } from 'rxjs';
import { DialogComponent } from '../../../dialog/dialog.component';
import { DialogData } from '../../../dialog/dialog.model';
import { DialogService } from '../../../dialog/dialog.service';
import { SetView } from '../../options-panel.actions';
import { OptionsPanelView } from '../../options-panel.state';
import { DownloadProgressComponent } from '../download-progress/download-progress.component';
import { ClearCompletedDownloads } from '../download.actions';
import { DownloadStatus, ScanDownload } from '../download.models';
import { DownloadService } from '../download.service';
import { DownloadState } from '../download.state';

@UntilDestroy()
@Component({
  selector: 'sd-download-status',
  standalone: true,
  imports: [
    CommonModule,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    DownloadProgressComponent,
  ],
  templateUrl: './download-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadStatusComponent implements OnDestroy {
  scanDownloads = this.store.selectSignal(DownloadState.downloads);
  hasDownloads = computed(() => this.scanDownloads().length > 0);

  private fileDownloads = this.getFileDownloads();
  overallProgress = this.getOverallProgress();
  clearDisabled = this.isClearDisabled();
  cancelDisabled = this.isCancelDisabled();

  private cancelDialogRef: MatDialogRef<DialogComponent> | null = null;

  constructor(
    private store: Store,
    private downloadService: DownloadService,
    private dialogService: DialogService,
  ) {}

  ngOnDestroy(): void {
    this.cancelDialogRef?.close();
  }

  close() {
    this.store.dispatch(new SetView(OptionsPanelView.None, false));
  }

  toggleExpanded(item: ScanDownload) {
    item.expanded = !item.expanded;
  }

  clearCompleted() {
    this.store.dispatch(new ClearCompletedDownloads());
  }

  cancelDownloads() {
    this.showCancelConfirmation().subscribe(() => this.downloadService.cancelAllDownloads());
  }

  private getFileDownloads() {
    return computed(() =>
      this.scanDownloads().flatMap((scan) => {
        return scan.files.filter((file) => isDefined(file.downloadProgress));
      }),
    );
  }

  private getOverallProgress() {
    return computed(() => {
      const fileDownloads = this.fileDownloads();
      return fileDownloads?.length <= 0
        ? this.store.selectSnapshot(DownloadState.getDownloadsProgress(fileDownloads))
        : null;
    });
  }

  private isClearDisabled() {
    return computed(() => {
      const files = this.fileDownloads();

      const noDownloadsComplete = !files.some((file) => {
        const status = file.downloadProgress?.status;
        return status === DownloadStatus.Complete || status === DownloadStatus.Failed;
      });

      return files.length === 0 || noDownloadsComplete;
    });
  }

  private isCancelDisabled() {
    return computed(() => {
      const files = this.fileDownloads();

      const allDownloadsComplete = files.every((file) => {
        const status = file.downloadProgress?.status;
        return status === DownloadStatus.Complete || status === DownloadStatus.Failed;
      });

      return files.length === 0 || allDownloadsComplete;
    });
  }

  private showCancelConfirmation() {
    const dialogData = new DialogData(
      'Cancel Downloads',
      `Are you sure you want to cancel all downloads?`,
      { text: 'Yes', color: 'danger' },
      { text: 'No', color: 'secondary' },
    );

    this.cancelDialogRef = this.dialogService.showMessage(dialogData);

    return this.cancelDialogRef.afterClosed().pipe(filter((confirmed) => confirmed ?? false));
  }
}
