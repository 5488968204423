import { inject, InjectionToken } from '@angular/core';
import { ILogger } from './logger-types';
import { LoggerService } from './logger.service';

export const LOGGER_SERVICE_NAME = new InjectionToken<string>('LOGGER_SERVICE_NAME');
export const LOGGER_SERVICE_LOGGERS = new InjectionToken<ILogger[]>('LOGGER_SERVICE_LOGGERS');

const serviceMap = new Map<string, LoggerService>();

export function injectLogger(name: string): LoggerService {
  const current = inject(LoggerService);
  if (!name) return current;
  if (serviceMap.has(name)) return serviceMap.get(name)!;

  const service = current.createNamedLogger(name) as LoggerService;
  serviceMap.set(name, service);
  return service;
}
