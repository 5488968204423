import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { Observable, catchError, filter, map, of, switchMap } from 'rxjs';
import { ConnectFile } from 'trimble-connect-workspace-api';
import { IngestionSource } from '../client-header/client-header.models';
import { ClientHeaderService } from '../client-header/client-header.service';
import { injectLogger } from '../logging/logger-injection';
import { ScandataModel } from '../scandata/scandata.models';
import { ScandataService } from '../scandata/scandata.service';
import { GET_SCAN_PROJECT_URL } from '../utils/get-scan-project-url';
import { AddTilingWorkflow, RemoveTilingWorkflow } from '../workflow/workflow.actions';
import { getExternalFileId } from './external-file-id-utils';
import { ConnectIngestion, ImportFile, ImportStatus } from './models/connect-ingestion';

@Injectable({
  providedIn: 'root',
})
export class ConnectIngestionService {
  private readonly getScanProjectUrl$ = inject(GET_SCAN_PROJECT_URL);
  private logger = injectLogger('ConnectIngestionService');

  constructor(
    private http: HttpClient,
    private scandataService: ScandataService,
    private store: Store,
    private clientHeaderService: ClientHeaderService,
  ) {}

  public createIngestion(file: ConnectFile, downloadUrl: string, ingestionSource: IngestionSource) {
    const externalFileId = getExternalFileId(file) as string;

    return this.store.dispatch(AddTilingWorkflow).pipe(
      switchMap(() => this.getScanByExternalFileId(file)),
      switchMap((scan) =>
        isDefined(scan)
          ? of(scan).pipe(map((scan) => ({ scan, exist: true })))
          : this.createPointcloud(file.name, externalFileId, ingestionSource).pipe(
              map((scan) => ({ scan, exist: false })),
            ),
      ),
      switchMap(({ scan, exist }) =>
        this.createImport(scan.id, scan.name, downloadUrl, ingestionSource).pipe(
          map((importStatus) => {
            // Temporary logging to test the case where more than one import is created
            // for a new tiling operation with the same pointcloud id
            if (exist) {
              this.logger.warn(`Connect Create Ingestion pointcloud ${scan.id} exists`, {
                file,
                scan,
                importStatus,
              });
            }

            return scan;
          }),
        ),
      ),
      switchMap(() => this.updateScanInStore(file)),
      switchMap(() => this.store.dispatch(RemoveTilingWorkflow)),
      catchError((err) => {
        this.store.dispatch(RemoveTilingWorkflow);
        this.logger.error(`Connect Create Ingestion error (${file.id} ${file.name})`, {}, err);
        throw err;
      }),
    );
  }

  public getIngestion(file: ConnectFile): Observable<ConnectIngestion> {
    return this.getScanByExternalFileId(file).pipe(
      filter((scan): scan is ScandataModel => isDefined(scan)),
      map((scan) => ({ file, scan })),
    );
  }

  public getScan(file: ConnectFile) {
    // Cater for scans with externalFileId = file.id
    return this.getScanByExternalFileId(file).pipe(
      switchMap((scan) =>
        isDefined(scan) ? of(scan) : this.scandataService.getScanByExternalFileId(file.id),
      ),
    );
  }

  private createImport(
    pointcloudId: string,
    name: string,
    sourceUrl: string,
    ingestionSource: IngestionSource,
  ) {
    const importFile: ImportFile = {
      sourceUrl,
      targetFileName: name,
    };

    return this.getScanProjectUrl$(`/pointclouds/${pointcloudId}/imports`).pipe(
      switchMap((url) =>
        this.http.post<ImportStatus>(
          url,
          {
            files: [importFile],
          },
          { headers: this.clientHeaderService.getClientHeaders(ingestionSource) },
        ),
      ),
    );
  }

  private createPointcloud(name: string, externalFileId: string, ingestionSource: IngestionSource) {
    return this.getScanProjectUrl$('/pointclouds').pipe(
      switchMap((url) =>
        this.http.post<ScandataModel>(
          url,
          {
            name,
            externalFileId,
          },
          { headers: this.clientHeaderService.getClientHeaders(ingestionSource) },
        ),
      ),
    );
  }

  private getScanByExternalFileId(file: ConnectFile) {
    const externalFileId = getExternalFileId(file) as string;
    return this.scandataService.getScanByExternalFileId(externalFileId);
  }

  private updateScanInStore(file: ConnectFile) {
    // scandataService.getScanByExternalFileId updates scan with
    // necessary properties and upserts into the store
    return this.getScanByExternalFileId(file);
  }
}
