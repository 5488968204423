import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isNil } from '@trimble-gcs/common';
import { ModusAutocomplete, ModusAutocompleteModule, ModusIconModule } from '@trimble-gcs/modus';
import { debounceTime, filter, finalize, map } from 'rxjs';
import { ErrorState } from '../../../../error-handling/error.state';
import { ScandataModel, UpdateScandataModel } from '../../../../scandata/scandata.models';
import { ScandataService } from '../../../../scandata/scandata.service';
import { MAX_TAG_LENGTH, TagService } from '../../../../tag/tag.service';
import { NotWhitespaceStringValidator } from '../../../../utils/not-whitespace-string-validator';

@UntilDestroy()
@Component({
  selector: 'sd-tagging',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ModusAutocompleteModule, ModusIconModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tagging.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggingComponent {
  scandataModel = input.required<ScandataModel>();
  saving = output<boolean>();

  isSaving = signal(false);
  saveError = this.store.selectSignal(ErrorState.hasError('scanDetailsSaveError'));

  tagSelector = this.getTagSelector();
  private tagSelectorValue = this.getTagSelectorValue();
  tagSelectorError = this.getTagSelectorError();

  private projectTags = this.getProjectTags();
  selectedTags = signal<string[]>([]);
  filteredTags = this.getFilteredTags();

  private autocomplete = viewChild.required<ModusAutocomplete<string>>('autocomplete');

  constructor(
    private store: Store,
    private tagService: TagService,
    private scandataService: ScandataService,
  ) {
    this.createScandataModelEffect();
    effect(() => this.registerAutoCompleteHandler());
  }

  selected(tag: string): void {
    if (this.tagSelector.invalid || isNil(tag)) return;

    this.selectedTags.update((tags) => [...tags, tag]);
    this.tagSelector.reset();
    this.saveScandataModel();
  }

  remove(tag: string): void {
    const index = this.selectedTags().indexOf(tag);

    if (index >= 0) {
      this.selectedTags.update((tags) => tags.toSpliced(index, 1));
      this.tagSelector.setValue(null);
      this.saveScandataModel();
    }
  }

  private createScandataModelEffect() {
    effect(
      () => {
        const scan = this.scandataModel();
        this.setSelectedTags(scan);
        this.setFormSaving(false);
      },
      { allowSignalWrites: true },
    );
  }

  private registerAutoCompleteHandler() {
    this.autocomplete()
      .inputKeydown$.pipe(
        filter((event) => event.key === 'Enter'),
        filter(() => isNil(this.autocomplete().activeOption)),
        filter(() => this.tagSelector.dirty && this.tagSelector.valid),
        filter(() => this.tagNotSelected()),
        map(() => this.findOrAddTag()),
        untilDestroyed(this),
      )
      .subscribe((tag) => {
        this.selected(tag);
        this.autocomplete().closePanel();
      });
  }

  private tagNotSelected() {
    const value = this.tagSelector.value as string;
    const index = this.selectedTags().findIndex((tag) => tag.toLowerCase() === value.toLowerCase());
    return index === -1;
  }

  private findOrAddTag() {
    const value = this.tagSelector.value as string;
    const tag = this.projectTags().find((tag) => tag.toLowerCase() === value.toLowerCase());
    return tag ?? value;
  }

  private setSelectedTags(scan: ScandataModel) {
    const scanTags = scan.tags ?? [];
    this.selectedTags.set([...scanTags]);
  }

  private setFormSaving(isSaving: boolean) {
    if (isSaving) {
      this.tagSelector.disable({ emitEvent: false });
    } else {
      this.tagSelector.enable({ emitEvent: false });
    }

    this.isSaving.set(isSaving);
    this.saving.emit(isSaving);
  }

  private getTagSelector() {
    return new FormControl<string | null>(null, {
      validators: [NotWhitespaceStringValidator, Validators.maxLength(MAX_TAG_LENGTH)],
      updateOn: 'change',
    });
  }

  private getTagSelectorValue() {
    return toSignal(this.tagSelector.valueChanges.pipe(debounceTime(100)), {
      initialValue: null,
    });
  }

  private getTagSelectorError() {
    return toSignal(
      this.tagSelector.statusChanges.pipe(
        map(() => {
          return this.tagSelector.hasError('maxlength')
            ? `Maximum ${MAX_TAG_LENGTH} characters allowed.`
            : '';
        }),
      ),
      { initialValue: '' },
    );
  }

  private saveScandataModel() {
    this.setFormSaving(true);

    const pointcloudId = this.scandataModel().id;
    const updateScandataModel: UpdateScandataModel = { tags: this.selectedTags() };

    return this.scandataService
      .updateScandataModel(pointcloudId, updateScandataModel)
      .pipe(finalize(() => this.setFormSaving(false)))
      .subscribe({
        error: () => {
          this.setSelectedTags(this.scandataModel());
        },
      });
  }

  private getProjectTags() {
    return toSignal(this.tagService.getTags(), { initialValue: [] });
  }

  private getFilteredTags() {
    return computed(() => {
      const projectTags = this.projectTags();
      const selectedTags = this.selectedTags();
      const filter = this.tagSelectorValue();

      const availableTags = projectTags.filter((tag) => !selectedTags.includes(tag));
      if (isNil(filter)) return availableTags;

      const filterValue = filter.toLowerCase();
      return availableTags.filter((tag) => tag.toLowerCase().includes(filterValue));
    });
  }
}
