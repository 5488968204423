import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  input,
  output,
} from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { CameraProjection } from '../../connect-3d-ext/camera/camera.models';
import { getRevisionString } from '../../connect/external-file-id-utils';
import {
  PointcloudStatus,
  ScandataDisplayStatus,
  ScandataLoadStatus,
  ScandataModel,
} from '../../scandata/scandata.models';
import {
  CurrentStation,
  Station,
  StationDisplayStatus,
  StationLoadStatus,
} from '../../station/station.models';
import {
  ScandataTreeIconComponent,
  TreeIcon,
} from '../scandata-tree-icon/scandata-tree-icon.component';
import { ScandataTreeMenuComponent } from '../scandata-tree-menu/scandata-tree-menu.component';

interface ItemData {
  scan: ScandataModel;
  version?: string;
}

@Component({
  selector: 'sd-scandata-tree',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ModusButtonModule,
    ModusTooltipModule,
    MatProgressSpinnerModule,
    ScandataTreeIconComponent,
    ScandataTreeMenuComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-tree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTreeComponent {
  data = input.required<ItemData[], ScandataModel[]>({
    transform: (value) => {
      return value.map((scan) => ({
        scan,
        version: getRevisionString(scan.externalFileId ?? '') ?? undefined,
      }));
    },
  });

  currentStation = input<CurrentStation>();
  cameraProjection = input.required<CameraProjection>();
  quotaExceeded = input(false);

  modelIconClick = output<ScandataModel>();
  modelNameClick = output<ScandataModel>();
  modelDetailClick = output<ScandataModel>();
  modelExpandClick = output<ScandataModel>();
  modelFitToViewClick = output<ScandataModel>();

  stationIconClick = output<Station>();
  stationNameClick = output<Station>();
  stationMoreClick = output<Station>();

  stationDisabledReason = computed(() =>
    this.cameraProjection() === CameraProjection.Orthographic
      ? 'Station view disabled in orthogonal mode.'
      : '',
  );

  stationTooltipDisabled = computed(
    () => this.cameraProjection() !== CameraProjection.Orthographic,
  );

  modelIconClicked(model: ScandataModel) {
    this.modelIconClick.emit(model);
  }

  modelNameClicked(model: ScandataModel) {
    this.modelNameClick.emit(model);
  }

  modelDetailClicked(model: ScandataModel) {
    this.modelDetailClick.emit(model);
  }

  modelFitToViewClicked(model: ScandataModel) {
    this.modelFitToViewClick.emit(model);
  }

  stationIconClicked(station: Station) {
    this.stationIconClick.emit(station);
  }

  stationNameClicked(station: Station) {
    if (this.loadStationDisabled()) return;

    this.stationNameClick.emit(station);
  }

  stationMoreClicked(station: Station) {
    this.stationMoreClick.emit(station);
  }

  toggleExpand(model: ScandataModel) {
    this.modelExpandClick.emit(model);
  }

  stationsLoading(model: ScandataModel) {
    return model.stationLoadStatus === StationLoadStatus.Loading;
  }

  fitToViewDisabled(model: ScandataModel) {
    return (
      model.displayStatus !== ScandataDisplayStatus.Displayed || isDefined(this.currentStation())
    );
  }

  loadModelDisabled(model: ScandataModel) {
    return (
      model.pointCount === 0 ||
      model.pointcloudStatus !== PointcloudStatus.Ready ||
      this.quotaExceeded()
    );
  }

  modelNotReady(model: ScandataModel) {
    return (
      model.pointcloudStatus === PointcloudStatus.Uploading ||
      model.pointcloudStatus === PointcloudStatus.Processing
    );
  }

  modelHasError(model: ScandataModel) {
    return this.getModelIcon(model) === TreeIcon.Error;
  }

  getModelInfoMessage(model: ScandataModel) {
    if (this.modelNotReady(model)) return 'Not ready for viewing';

    if (model.pointcloudStatus === PointcloudStatus.Failed) {
      return 'Failed to ingest';
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return 'Failed to load';
    }

    if (model.pointCount === 0) {
      return 'Stations only';
    }

    return null;
  }

  getModelIcon(model: ScandataModel): TreeIcon {
    if (this.modelNotReady(model) || this.quotaExceeded()) return TreeIcon.Unavailable;

    if (model.pointcloudStatus === PointcloudStatus.Failed) {
      return TreeIcon.Error;
    }

    if (
      model.loadStatus === ScandataLoadStatus.LoadingError ||
      model.displayStatus === ScandataDisplayStatus.DisplayError
    ) {
      return TreeIcon.Error;
    }

    if (
      model.loadStatus === ScandataLoadStatus.Loading ||
      model.displayStatus === ScandataDisplayStatus.AwaitingDisplay
    ) {
      return TreeIcon.Loading;
    }

    if (!model.showInScene) {
      return TreeIcon.Off;
    }

    return TreeIcon.On;
  }

  stationHasError(station: Station) {
    return this.getStationIcon(station) === TreeIcon.Error;
  }

  getStationErrorMessage(station: Station) {
    if (this.getStationIcon(station) === TreeIcon.Error) {
      return 'Failed to load';
    }

    return null;
  }

  getStationIcon(station: Station): TreeIcon {
    if (this.quotaExceeded()) return TreeIcon.Unavailable;

    const currentStation = this.currentStation();
    const viewed = station.id === currentStation?.station.id;
    if (viewed) {
      if (currentStation.displayStatus === StationDisplayStatus.AwaitingDisplay) {
        return TreeIcon.Loading;
      }

      if (currentStation.displayStatus === StationDisplayStatus.DisplayError) {
        return TreeIcon.Error;
      }

      if (currentStation.displayStatus === StationDisplayStatus.Displayed) {
        return TreeIcon.On;
      }

      return TreeIcon.Off;
    } else {
      return TreeIcon.Off;
    }
  }

  loadStationDisabled() {
    const currentStation = this.currentStation();

    return (
      currentStation?.displayStatus === StationDisplayStatus.AwaitingDisplay ||
      this.cameraProjection() === CameraProjection.Orthographic ||
      this.quotaExceeded()
    );
  }
}
