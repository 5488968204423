import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  computed,
  inject,
  input,
  output,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModusTooltipModule, isDefined } from '@trimble-gcs/modus';
import {
  Observable,
  combineLatest,
  distinctUntilChanged,
  filter,
  fromEvent,
  map,
  shareReplay,
  switchMap,
} from 'rxjs';

// CSS properties
export const CHIP_CONTAINER_GAP = 8;
export const CHIP_HEIGHT = 32 + CHIP_CONTAINER_GAP;
export const CHIP_MIN_WIDTH = 44 + CHIP_CONTAINER_GAP;

export type ChipContainerSize = { height: number; width: number };
@UntilDestroy()
@Component({
  selector: 'sd-chip-container',
  standalone: true,
  imports: [CommonModule, ModusTooltipModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './chip-container.component.html',
  styles: [
    `
      :host {
        display: block;
        content-visibility: auto;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipContainerComponent {
  private element = inject(ElementRef).nativeElement;

  // inputs
  chips = input.required<string[]>();
  containerSize = input.required<ChipContainerSize>();
  offscreenChipCount = input.required<number>();

  // outputs
  chipClick = output<MouseEvent>();

  // viewChildren
  private offscreenChipElements = viewChildren<ElementRef<HTMLDivElement>>('offscreenChipElement');
  private offscreenSummaryElement =
    viewChild<ElementRef<HTMLDivElement>>('offscreenSummaryElement');

  // observables
  private chips$ = toObservable(this.chips);
  private containerSize$ = toObservable(this.containerSize);
  private contentVisibility$: Observable<boolean> = this.getContentVisibilityObservable();
  private offscreenChipElements$ = this.getOffscreenChipElementsObservable();

  // offscreen signals
  contentVisible = toSignal(this.contentVisibility$, { initialValue: false });
  offscreenChips = computed(() => this.chips().slice(0, this.offscreenChipCount()));
  offscreenSummary = computed(() => `+${this.chips().length}`);

  // onscreen signals
  visibleChips = signal<string[]>([]);
  summaryChip = signal<string>('');
  showSummary = signal<boolean>(false);
  summaryTooltip = computed(() => this.chips().slice(this.visibleChips().length).join(', '));

  constructor() {
    this.subscribeToChipVisibilityChanges();
  }

  chipContainerClick(event: MouseEvent) {
    this.chipClick.emit(event);
  }

  private getContentVisibilityObservable(): Observable<boolean> {
    return fromEvent<ContentVisibilityAutoStateChangeEvent>(
      this.element,
      'contentvisibilityautostatechange',
    ).pipe(
      map((event) => !event.skipped),
      shareReplay({ refCount: true, bufferSize: 1 }),
    );
  }

  private getOffscreenChipElementsObservable() {
    return combineLatest([
      toObservable(this.offscreenChipElements),
      toObservable(this.offscreenSummaryElement).pipe(filter(isDefined)),
    ]).pipe(
      map(([elementRefs, summaryRef]) => {
        return {
          elements: elementRefs.map((ref) => ref.nativeElement),
          summary: summaryRef.nativeElement,
        };
      }),
    );
  }

  private subscribeToChipVisibilityChanges() {
    this.contentVisibility$
      .pipe(
        filter((visible) => visible),
        switchMap(() => combineLatest([this.chips$, this.containerSize$])),
        switchMap(([chips, size]) => {
          return this.offscreenChipElements$.pipe(
            filter(({ elements }) => elements.length === this.offscreenChips().length),
            map((offscreen) => ({ chips, size, ...offscreen })),
          );
        }),
        distinctUntilChanged(
          (previous, current) =>
            previous.size.width === current.size.width &&
            previous.size.height === current.size.height &&
            previous.chips.length === current.chips.length &&
            previous.chips.every((value, index) => value === current.chips[index]),
        ),
        untilDestroyed(this),
      )
      .subscribe({
        next: ({ chips, size, elements, summary }) =>
          this.setVisibleChips(chips, size, elements, summary),
      });
  }

  private setVisibleChips(
    chips: string[],
    containerSize: ChipContainerSize,
    offscreenElements: HTMLElement[],
    summaryElement: HTMLElement,
  ) {
    const { height, width } = containerSize;

    if (offscreenElements.length === 0 || CHIP_HEIGHT > height) return this.visibleChips.set([]);

    const visibleChips = [];

    let currentRowWidth = 0;
    let currentHeight = CHIP_HEIGHT;
    let firstChipOfRow = true;

    // fit chips into the onscreen container
    for (let i = 0; i < offscreenElements.length; i++) {
      const chipRect = offscreenElements[i].getBoundingClientRect();
      const chipWidth = firstChipOfRow ? chipRect.width : chipRect.width + CHIP_CONTAINER_GAP;
      firstChipOfRow = false;

      if (currentRowWidth + chipWidth > width) {
        if (currentHeight + CHIP_HEIGHT <= height) {
          currentRowWidth = 0;
          currentHeight += CHIP_HEIGHT;
          firstChipOfRow = true;
        } else break;
      }

      visibleChips.push(chips[i]);
      currentRowWidth += chipWidth;
    }

    // summary chip
    this.showSummary.set(chips.length > visibleChips.length);

    if (this.showSummary()) {
      const summaryWidth = summaryElement.getBoundingClientRect().width;

      for (let i = visibleChips.length; i > 0; i--) {
        if (currentRowWidth + summaryWidth <= width) {
          this.summaryChip.set(`+${chips.length - visibleChips.length}`);
          break;
        } else {
          visibleChips.pop();
          const lastElementWidth = offscreenElements[i].getBoundingClientRect().width;
          currentRowWidth -= lastElementWidth + CHIP_CONTAINER_GAP;
        }
      }
    }

    this.visibleChips.set(visibleChips);
  }
}
