import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { filter } from 'rxjs';
import { AppState } from '../app-state/app.state';
import { ILogger, LogLevel, LoggerType, logLevelValues } from './logger-types';

export abstract class AbstractLogger implements ILogger {
  protected store = inject(Store);
  protected level: LogLevel = LogLevel.None;

  abstract readonly type: LoggerType;

  constructor(public readonly name: string) {
    this.store
      .select(AppState.settings)
      .pipe(filter(isDefined))
      .subscribe((settings) => {
        const logSettings = settings.logging.loggers[this.type];
        this.level = logSettings?.logLevel ?? LogLevel.None;
      });
  }

  abstract createNamedLogger(name: string): ILogger;

  debug(message: string, context?: object, error?: Error): void {
    this.log(LogLevel.Debug, message, context, error);
  }

  info(message: string, context?: object, error?: Error): void {
    this.log(LogLevel.Info, message, context, error);
  }

  warn(message: string, context?: object, error?: Error): void {
    this.log(LogLevel.Warn, message, context, error);
  }

  error(message: string, context?: object, error?: Error): void {
    this.log(LogLevel.Error, message, context, error);
  }

  protected abstract log(level: LogLevel, message: string, context?: object, error?: Error): void;

  /**
   * Ignore messages below the current LogLevel.
   */
  protected ignore(level: LogLevel): boolean {
    if (this.level === LogLevel.None) return true;

    const thisLevel = logLevelValues[this.level];
    const callLevel = logLevelValues[level];

    return callLevel < thisLevel;
  }
}
