import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import {
  ExistingState,
  StateOperator,
  append,
  compose,
  patch,
  removeItem,
  updateItem,
} from '@ngxs/store/operators';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ConnectView } from '../connect-3d-ext/host-3d.model';
import {
  DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE,
  Filters,
  PageInfo,
  ScandataQuery,
  SortInfo,
} from './scandata-query.models';
import {
  AddScan,
  ClearFilters,
  ClearScandata,
  PatchScandataModel,
  PatchScandataModels,
  RemoveScandataModel,
  SelectOnly,
  SelectScandataModel,
  SetFilters,
  SetIsLoading,
  SetPageInfo,
  SetScandata,
  SetSelected,
  SetSortInfo,
  SetTextFilter,
  UnselectAllScandataModels,
  UnselectScandataModel,
  UpdateScandata,
} from './scandata.actions';
import { ScandataModel } from './scandata.models';

export interface ScandataStateModel {
  scandata: ScandataModel[];
  isLoading: boolean;
  textFilter?: string;
  scandataQuery: ScandataQuery;
}

const defaultFilters: Filters = { tagsMatchAll: DEFAULT_FILTER_TAGS_MATCH_ALL_VALUE };

const defaultState: ScandataStateModel = {
  scandata: [],
  isLoading: false,
  scandataQuery: {
    sortInfo: { sortBy: <keyof ScandataModel>'uploadedDate', sortDirection: 'desc' },
    pageInfo: { pageIndex: 0, pageSize: 1000 },
    filters: defaultFilters,
  },
};

@State<ScandataStateModel>({
  name: 'scandataState',
  defaults: defaultState,
})
@Injectable()
export class ScandataState {
  @Selector() static scandata(state: ScandataStateModel): ScandataModel[] {
    return state.scandata;
  }

  @Selector() static textFilteredScandata(state: ScandataStateModel): ScandataModel[] {
    const textFilter = state.textFilter?.trim();
    if (isNil(textFilter) || textFilter.length === 0) return state.scandata;

    const filter = textFilter.toLowerCase();
    return state.scandata.filter((scan) => {
      return (
        scan.name.toLowerCase().includes(filter) ||
        scan.uploadedBy?.toLowerCase().includes(filter) ||
        scan.notes?.toLowerCase().includes(filter) ||
        scan.scannerType?.toLowerCase().includes(filter) ||
        scan.tags?.some((tag) => tag.toLowerCase().includes(filter)) ||
        scan.location?.city.toLowerCase().includes(filter) ||
        scan.location?.country.toLowerCase().includes(filter) ||
        scan.location?.state.toLowerCase().includes(filter) ||
        scan.location?.stateName.toLowerCase().includes(filter) ||
        scan.location?.streetAddress.toLowerCase().includes(filter) ||
        scan.location?.zip.toLowerCase().includes(filter)
      );
    });
  }

  @Selector() static selected(state: ScandataStateModel): ScandataModel[] {
    return state.scandata.filter((model) => model.selected);
  }

  @Selector() static chronologicalSelected(state: ScandataStateModel): ScandataModel[] {
    return [...state.scandata.filter((model) => model.selected)].sort(
      (a, b) => (a.selectedIndex ?? 0) - (b.selectedIndex ?? 0),
    );
  }

  @Selector() static isLoading(state: ScandataStateModel): boolean {
    return state.isLoading;
  }

  @Selector() static textFilter(state: ScandataStateModel): string | undefined {
    return state.textFilter;
  }

  @Selector() static query(state: ScandataStateModel): ScandataQuery {
    return state.scandataQuery;
  }

  @Selector() static sortInfo(state: ScandataStateModel): SortInfo {
    return state.scandataQuery.sortInfo;
  }

  @Selector() static pageInfo(state: ScandataStateModel): PageInfo {
    return state.scandataQuery.pageInfo;
  }

  @Selector() static filters(state: ScandataStateModel): Filters {
    return state.scandataQuery.filters;
  }

  @Selector() static filterCount(state: ScandataStateModel): number {
    const defaultFilterCount = Object.values(defaultFilters).filter(
      (value) => isDefined(value) && value.toString().length > 0,
    ).length;

    const selectedFilters = state.scandataQuery.filters;
    const selectedFilterCount = Object.values(selectedFilters).filter(
      (value) => isDefined(value) && value.toString().length > 0,
    ).length;

    return selectedFilterCount - defaultFilterCount;
  }

  static getScansForConnectView(connectView: ConnectView) {
    return createSelector([ScandataState], (state: ScandataStateModel) => {
      return state.scandata.filter((scan) => connectView.scans.find((x) => x.id === scan.id));
    });
  }

  static getScan(id: string) {
    return createSelector([ScandataState], (state: ScandataStateModel) => {
      return state.scandata.find((scan) => id === scan.id);
    });
  }

  static getScanByExternalFileId(externalFileId: string) {
    return createSelector([ScandataState], (state: ScandataStateModel) => {
      return state.scandata.find((scan) => externalFileId === scan.externalFileId);
    });
  }

  @Action(UpdateScandata) updateScandata(
    ctx: StateContext<ScandataStateModel>,
    { scandata }: UpdateScandata,
  ) {
    ctx.setState(patch<ScandataStateModel>({ scandata: updateScandata(scandata) }));
  }

  @Action(SetScandata) setScandata(
    ctx: StateContext<ScandataStateModel>,
    { scandata }: SetScandata,
  ) {
    ctx.setState(patch<ScandataStateModel>({ scandata: scandata }));
  }

  @Action(ClearScandata) clearScandata(ctx: StateContext<ScandataStateModel>) {
    ctx.setState(patch<ScandataStateModel>({ scandata: [] }));
  }

  @Action(SelectScandataModel) selectScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: SelectScandataModel,
  ) {
    const lastSelectedIndex = getLastSelectedIndex(ctx.getState().scandata);

    ctx.setState(
      patch({
        scandata: updateItem(
          (model) => model.id === scandataModelId,
          (model) => {
            return { ...model, ...{ selected: true, selectedIndex: lastSelectedIndex + 1 } };
          },
        ),
      }),
    );
  }

  @Action(UnselectScandataModel) unselectScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: UnselectScandataModel,
  ) {
    ctx.setState(
      patch({
        scandata: updateItem(
          (model) => model.id === scandataModelId,
          (model) => {
            return { ...model, ...{ selected: false, selectedIndex: undefined } };
          },
        ),
      }),
    );
  }

  @Action(SelectOnly) selectOnly(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelIds }: SelectOnly,
  ) {
    const data = ctx.getState().scandata.slice();

    data.forEach((model) => {
      const selected = scandataModelIds.includes(model.id);

      model.selected = selected;
      model.selectedIndex = selected
        ? scandataModelIds.findIndex((id) => model.id === id)
        : undefined;
    });

    ctx.patchState({ scandata: data });
  }

  @Action(SetSelected) setSelected(ctx: StateContext<ScandataStateModel>, { scans }: SetSelected) {
    const data = ctx.getState().scandata.slice();
    let nextAvailableIndex = getLastSelectedIndex(data) + 1;

    data.forEach((model) => {
      const updateScan = scans.find((s) => s.id === model.id);
      if (isNil(updateScan)) return;

      const selected = updateScan.selected;

      model.selected = selected;
      model.selectedIndex = selected
        ? scans.filter((scan) => scan.selected).findIndex((s) => s.id === model.id) +
          nextAvailableIndex
        : undefined;
    });

    ctx.patchState({ scandata: data });
  }

  @Action(UnselectAllScandataModels) unselectAllScandataModels(
    ctx: StateContext<ScandataStateModel>,
  ) {
    const data = ctx.getState().scandata.slice();
    data.forEach((model) => {
      model.selected = false;
      model.selectedIndex = undefined;
    });
    ctx.patchState({ scandata: data });
  }

  @Action(PatchScandataModel) patchScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModel }: PatchScandataModel,
  ) {
    this.patchScandataModels(ctx, new PatchScandataModels([scandataModel]));
  }

  @Action(PatchScandataModels) patchScandataModels(
    ctx: StateContext<ScandataStateModel>,
    { scandata }: PatchScandataModels,
  ) {
    const updateItems = scandata.map((model) =>
      updateItem<ScandataModel>(
        (item) => item.id === model.id,
        (item) => ({ ...item, ...model }),
      ),
    );

    ctx.setState(
      patch({
        scandata: compose(...updateItems),
      }),
    );
  }

  @Action(AddScan) addScan(ctx: StateContext<ScandataStateModel>, { scan }: AddScan) {
    ctx.setState(
      patch({
        scandata: append([scan]),
      }),
    );
  }

  @Action(RemoveScandataModel) removeScandataModel(
    ctx: StateContext<ScandataStateModel>,
    { scandataModelId }: RemoveScandataModel,
  ) {
    ctx.setState(
      patch({
        scandata: removeItem<ScandataModel>((item) => item.id === scandataModelId),
      }),
    );
  }

  @Action(SetTextFilter) setTextFilter(
    ctx: StateContext<ScandataStateModel>,
    { textFilter }: SetTextFilter,
  ) {
    ctx.patchState({ textFilter });
  }

  @Action(SetSortInfo) setSortInfo(
    ctx: StateContext<ScandataStateModel>,
    { sortInfo }: SetSortInfo,
  ) {
    ctx.setState(
      patch({
        scandataQuery: patch({ sortInfo }),
      }),
    );
  }

  @Action(SetPageInfo) setPageInfo(
    ctx: StateContext<ScandataStateModel>,
    { pageInfo }: SetPageInfo,
  ) {
    ctx.setState(
      patch({
        scandataQuery: patch({ pageInfo }),
      }),
    );
  }

  @Action(SetFilters) setFilters(ctx: StateContext<ScandataStateModel>, { filters }: SetFilters) {
    ctx.setState(
      patch({
        scandataQuery: patch({ filters }),
      }),
    );
  }

  @Action(ClearFilters) clearFilters(ctx: StateContext<ScandataStateModel>) {
    ctx.setState(
      patch({
        scandataQuery: patch({ filters: { ...defaultFilters } }),
      }),
    );
  }

  @Action(SetIsLoading) setIsLoading(
    ctx: StateContext<ScandataStateModel>,
    { isLoading }: SetIsLoading,
  ) {
    ctx.patchState({ isLoading: isLoading });
  }
}

function updateScandata(data: ScandataModel[]): StateOperator<ScandataModel[]> {
  return (existing: ExistingState<ScandataModel[]>) => {
    const updated = data.map((item) => {
      const current = existing.find((ex) => ex.id === item.id);

      // Keeping web3dId the same as current item is important
      // when going to 3d viewer from selection.
      // The scans in host-3d is set first and getScandata updates
      // the scans later.
      const web3dId = current?.web3dId ?? item.web3dId;

      const merged = isNil(current) ? item : { ...current, ...item, web3dId };
      return merged;
    });
    return updated;
  };
}

function getLastSelectedIndex(data: ScandataModel[]) {
  return (
    data
      .map((scan) => scan.selectedIndex)
      .filter(isDefined)
      .toSorted((a, b) => a - b)
      .at(-1) ?? -1
  );
}
