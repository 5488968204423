import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  input,
  output,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { filter, switchMap } from 'rxjs';
import { ConnectService } from '../../../connect/connect.service';
import { DialogComponent } from '../../../dialog/dialog.component';
import { CANCEL_BUTTON, DialogData } from '../../../dialog/dialog.model';
import { DialogService } from '../../../dialog/dialog.service';
import { ClearError } from '../../../error-handling/error.actions';
import { LoadingService } from '../../../loading/loading.service';
import { UnselectScandataModel } from '../../../scandata/scandata.actions';
import { ScandataModel } from '../../../scandata/scandata.models';
import { ScandataService } from '../../../scandata/scandata.service';
import { SetInfoView, SetPreviewVisible } from '../../options-panel.actions';
import { OptionsPanelInfoView, OptionsPanelState } from '../../options-panel.state';
import { ClassificationComponent } from './classification/classification.component';
import { PropertiesComponent } from './properties/properties.component';
import { TaggingComponent } from './tagging/tagging.component';
import { ViewerGlbComponent } from './viewer-glb/viewer-glb.component';

@UntilDestroy()
@Component({
  selector: 'sd-single-selected',
  standalone: true,
  imports: [
    CommonModule,
    ModusButtonModule,
    ModusIconModule,
    ModusTooltipModule,
    ViewerGlbComponent,
    ClassificationComponent,
    PropertiesComponent,
    TaggingComponent,
    ScrollingModule,
    MatTabsModule,
    MatProgressBarModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './single-selected.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectedComponent implements OnDestroy {
  scandataModel = input.required<ScandataModel>();
  readonly = input(false);

  private isSaving = signal(false);
  private isLoading = toSignal(this.loadingService.isLoading$(this));
  showProgressBar = computed(() => this.isSaving() || this.isLoading());

  private showPreview = this.store.selectSignal(OptionsPanelState.previewVisible);
  private hasPreview = this.scanHasPreview();

  previewButtonDisabled = computed(() => !this.hasPreview());
  previewButtonTooltip = this.getPreviewButtonTooltip();
  previewVisible = computed(() => this.hasPreview() && this.showPreview());

  private infoTabs = this.getInfoTabs();
  private infoView = this.store.selectSignal(OptionsPanelState.infoView);
  selectedInfoTabIndex = this.getselectedInfoTabIndex();

  downloadClicked = output();

  private deleteDialogRef: MatDialogRef<DialogComponent> | null = null;

  constructor(
    private store: Store,
    private loadingService: LoadingService,
    private scandataService: ScandataService,
    private connectService: ConnectService,
    private dialogService: DialogService,
  ) {}

  ngOnDestroy(): void {
    this.deleteDialogRef?.close();
  }

  togglePreview() {
    const previewVisible = !this.showPreview();
    this.store.dispatch(new SetPreviewVisible(previewVisible));
  }

  infoTabChange(event: MatTabChangeEvent) {
    const tab = Object.keys(this.infoTabs)[event.index] as OptionsPanelInfoView;
    this.store.dispatch([new SetInfoView(tab), new ClearError('scanDetailsSaveError')]);
  }

  viewIn3d() {
    this.connectService.goTo3dExtension();
  }

  unselect() {
    this.store.dispatch(new UnselectScandataModel(this.scandataModel().id));
  }

  handleSave(saving: boolean) {
    this.isSaving.set(saving);
  }

  deleteScandata() {
    const dialogData = new DialogData(
      'Delete Reality Capture Data',
      'Are you sure you want to delete this data?',
      { text: 'Delete', color: 'danger' },
      CANCEL_BUTTON,
    );

    this.deleteDialogRef = this.dialogService.showMessage(dialogData);

    this.deleteDialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed ?? false),
        switchMap(() => {
          return this.loadingService.loadFrom(
            this.scandataService.deleteScandataModel(this.scandataModel().id),
            this,
          );
        }),
      )
      .subscribe();
  }

  private scanHasPreview() {
    return computed(() => {
      const previewUrl = this.scandataModel().previewUrl;
      const hasPreview = isDefined(previewUrl) && previewUrl.length > 0;
      return hasPreview;
    });
  }

  private getPreviewButtonTooltip() {
    return computed(() => {
      if (!this.hasPreview()) return 'No preview available';
      return this.showPreview() ? 'Hide preview' : 'Show preview';
    });
  }

  private getInfoTabs() {
    return {
      [OptionsPanelInfoView.Properties]: 0,
      [OptionsPanelInfoView.Tagging]: 1,
      [OptionsPanelInfoView.Classification]: 2,
    };
  }

  private getselectedInfoTabIndex() {
    return computed(() => {
      const infoView = this.infoView();
      return this.infoTabs[infoView];
    });
  }
}
