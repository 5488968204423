import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, Signal, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconRegistry } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { Store } from '@ngxs/store';
import { ModusIconModule } from '@trimble-gcs/modus';
import { filter, map } from 'rxjs';
import { AppRoute } from '../../app.routes';
import { AuthService } from '../../auth/auth.service';
import { AuthState } from '../../auth/auth.state';
import { UserState } from '../../user/user.state';
import { AppBarComponent } from '../app-bar/app-bar.component';
import { TabHostComponent } from '../tab-host/tab-host.component';

type RouteKey = `${AppRoute}` | 'loading-demo' | 'dialog-demo';

@Component({
  selector: 'sd-app-host',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ModusIconModule,
    AppBarComponent,
    TabHostComponent,
    MatSidenavModule,
    MatListModule,
  ],
  templateUrl: './app-host.component.html',
  styleUrls: ['./app-host.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppHostComponent {
  private readonly sidenavToggle = signal(false);

  readonly loggedIn = this.store.selectSignal(AuthState.loggedIn);
  readonly user = this.store.selectSignal(UserState.user);
  readonly sidenavOpen = computed(() => this.loggedIn() && this.sidenavToggle());
  readonly activeRoute: Signal<RouteKey | undefined> = this.subscribeToActiveRoute();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private store: Store,
    private authService: AuthService,
  ) {
    this.registerIcons();
    this.authService.registerTokenExpiryWatch().subscribe();
  }

  toggleSidenav(evt: MouseEvent) {
    if (evt.ctrlKey && evt.shiftKey) {
      this.sidenavToggle.update((value) => !value);
    }
  }

  navigate(path: RouteKey) {
    this.router.navigate([path]);
  }

  logout() {
    this.router.navigate([AppRoute.Logout]);
  }

  private registerIcons() {
    const icons = [{ name: 'logo-dark-gray', resourceUrl: '/assets/logo_darkgray_24dp.svg' }];

    icons.forEach((icon) => {
      this.matIconRegistry.addSvgIcon(
        icon.name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.resourceUrl),
      );
    });
  }

  private subscribeToActiveRoute() {
    const regex = /\/?([^\/?]+)(\?.*)?/;

    const active$ = this.router.events.pipe(
      filter((evt) => evt instanceof NavigationEnd),
      map((evt) => {
        const url = (evt as NavigationEnd).url;
        return url.match(regex)?.at(1) as RouteKey;
      }),
    );

    return toSignal(active$);
  }
}
