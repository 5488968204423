@if (showBackNavigation()) {
  <div class="flex px-4 pt-2 w-full text-lg items-center bg-gray-light">
    <div
      id="settings-back-to-scandata"
      class="p-1 text-primary cursor-pointer hover:bg-gray-0"
      (click)="viewScandata()"
    >
      Reality Capture
    </div>

    <div class="mx-1 text-sm text-gray-4">></div>
    <div>Settings</div>
  </div>
}
<div class="h-full flex flex-1 p-4 pt-2 bg-gray-light">
  <mat-tab-group
    class="flex-1"
    disableRipple
    mat-stretch-tabs="false"
    [selectedIndex]="selectedTabIndex()"
    (selectedTabChange)="tabChange($event)"
  >
    <mat-tab label="Cloud Storage">
      <ng-template matTabContent>
        <sd-config-storage
          class="flex flex-1 flex-col border-t border-gray-0 pt-4"
        ></sd-config-storage>
      </ng-template>
    </mat-tab>
    <mat-tab label="Classification Template">
      <ng-template matTabContent>
        <sd-config-classification
          class="h-full flex flex-1 flex-col border-t border-gray-0 pt-4"
        ></sd-config-classification>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</div>
