import { CommonModule } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { catchError, EMPTY, finalize, repeat, Subject, switchMap } from 'rxjs';
import { isApiErrorType } from '../../error-handling/api-error-type';
import { LoadingService } from '../../loading/loading.service';
import { LicenseService } from '../license.service';
import { LicenseState } from '../license.state';

enum LicenseView {
  LoadLicense,
  NoLicense,
  AccountNotAllowed,
  UnknownError,
}

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule, MatProgressBarModule, ModusButtonModule],
  templateUrl: './license-check.component.html',
  styles: [
    `
      :host {
        height: 100%;
        background-color: white;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseCheckComponent {
  view = LicenseView;

  isLoading = toSignal(this.loadingService.isLoading$(this), { initialValue: false });
  currentView = signal(LicenseView.LoadLicense);

  private readonly reloadLicenses$ = new Subject<void>();

  constructor(
    private loadingService: LoadingService,
    private licenseService: LicenseService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
  ) {
    this.loadLicenses();
  }

  reloadLicense() {
    this.reloadLicenses$.next();
  }

  private loadLicenses() {
    this.loadingService
      .loadFrom(this.licenseService.loadLicenses(), this)
      .pipe(
        catchError((err: HttpErrorResponse) => this.handleError(err)),
        switchMap(() => this.store.select(LicenseState.hasActiveLicense)),
        finalize(() => this.loadingService.stopLoading(this)),
        repeat({ delay: () => this.reloadLicenses$ }),
        untilDestroyed(this),
      )
      .subscribe((hasActiveLicense) => {
        hasActiveLicense
          ? this.navigateToReturnPath()
          : this.currentView.set(LicenseView.NoLicense);
      });
  }

  private handleError(err: HttpErrorResponse) {
    const view = isApiErrorType(err, 'ACCOUNT_NOT_ALLOWED')
      ? LicenseView.AccountNotAllowed
      : LicenseView.UnknownError;

    this.currentView.set(view);
    return EMPTY;
  }

  private navigateToReturnPath() {
    const returnPath = this.activatedRoute.snapshot.queryParamMap.get('returnPath') ?? '';
    this.router.navigate([returnPath]);
  }
}
