<div class="flex flex-col h-full">
  <!--
  Use ngClass instead of ngSwitch to hide the list component.
  This prevents the component from being destroyed and
  loosing it's scroll position when other components are shown.
  -->
  <sd-scan-3d-list
    [quotaExceeded]="quotaExceeded()"
    (exitStationClick)="onExitStationClick()"
    (settingsClick)="onSettingsClick()"
    (modelDetailClick)="onModelDetailClick($event)"
    class="flex flex-col h-full"
    [ngClass]="{ hidden: listHidden() }"
  ></sd-scan-3d-list>

  @switch (currentView()) {
    @case (scan3dView.Details) {
      @let scan = currentScan();
      @let style = scan3dStyle();

      @if (scan && style) {
        <sd-scan-3d-detail
          [currentModel]="scan"
          [scan3dStyle]="style"
          (exitDetailClick)="onExitDetailClick()"
          (styleChange)="onStyleChange($event)"
          class="flex flex-col h-full"
        ></sd-scan-3d-detail>
      } @else {
        <div>No model error</div>
      }
    }
    @case (scan3dView.Settings) {
      <sd-scan-3d-settings
        (exitSettingsClick)="onExitSettingsClick()"
        class="flex flex-col h-full"
      ></sd-scan-3d-settings>
    }
  }
</div>
