import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { map, of, switchMap } from 'rxjs';
import { ClassificationService } from '../classification/classification.service';
import { Scan3dStyle } from './models/scan-3d-style';
import { SetGlobalStyle } from './scan-3d.actions';
import { Scan3dState } from './scan-3d.state';

@Injectable({
  providedIn: 'root',
})
export class Scan3dService {
  constructor(
    private store: Store,
    private classificationService: ClassificationService,
  ) {}

  getGlobalStyle() {
    return this.store.select(Scan3dState.globalStyle).pipe(
      switchMap((globalStyle) => {
        return isDefined(globalStyle) ? of(globalStyle) : this.getAndCacheGlobalStyle();
      }),
    );
  }

  private getAndCacheGlobalStyle() {
    return this.classificationService.getClassificationSchemes().pipe(
      map((schemes) => {
        // return new instances of scheme's, that will store changes for this user session only
        return schemes.map((scheme) => ({ ...scheme }));
      }),
      map((schemes) => {
        const style: Scan3dStyle = {
          showClassification: false,
          showIntensity: false,
          showEyeDomeLighting: true,
          pointBudget: 30000000,
          pointDensity: 1,
          pointSize: 2,
          classificationSchemes: schemes,
          intensityOffset: 0.5,
          intensityStartColor: '#f70',
          intensityEndColor: '#07f',
        };

        return style;
      }),
      switchMap((style) => {
        return this.store.dispatch(new SetGlobalStyle(style)).pipe(map(() => style));
      }),
    );
  }
}
