<button id="3d-sort" class="text-gray-8" modus-icon-button [modusMenuTriggerFor]="menu">
  <md-icon>sort</md-icon>
</button>

<md-menu #menu>
  @for (item of menuItems(); track item.sortableProperty.property) {
    <md-menu-item (itemClick)="onSortClick(item)" class="w-16">
      <span class="w-12">{{ item.sortableProperty.displayName }}</span>
      @switch (item.direction) {
        @case ('asc') {
          <md-icon class="!text-sm">sort_arrow_up</md-icon>
        }
        @case ('desc') {
          <md-icon class="!text-sm">sort_arrow_down</md-icon>
        }
      }
    </md-menu-item>
  }
</md-menu>
