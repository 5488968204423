import { Injectable, inject } from '@angular/core';
import {
  Action,
  NgxsOnInit,
  Selector,
  State,
  StateContext,
  StateOperator,
  createSelector,
} from '@ngxs/store';
import { ExistingState, patch } from '@ngxs/store/operators';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  AddToDownload,
  ClearAllDownloads,
  ClearCompletedDownloads,
  PatchFileDownload,
} from './download.actions';
import { DownloadProgress, DownloadStatus, FileDownload, ScanDownload } from './download.models';
import { DownloadService } from './download.service';

export interface DownloadStateModel {
  downloads: ScanDownload[];
}

const defaultState: DownloadStateModel = {
  downloads: [],
};

@State<DownloadStateModel>({
  name: 'downloadState',
  defaults: defaultState,
})
@Injectable()
export class DownloadState implements NgxsOnInit {
  ngxsOnInit(): void {
    inject(DownloadService).subscribeDownloadWatcher();
  }

  static getDownloadsProgress(
    fileDownloads: FileDownload[],
  ): (state: DownloadStateModel) => DownloadProgress | null {
    return createSelector([DownloadState], () => {
      return getProgress(fileDownloads);
    });
  }

  @Selector() static downloads(state: DownloadStateModel): ScanDownload[] {
    return state.downloads;
  }

  @Selector() static activeDownloadCount(state: DownloadStateModel): number {
    const busy = state.downloads.flatMap((scan) =>
      scan.files.filter(
        (file) =>
          file.downloadProgress?.status === DownloadStatus.Pending ||
          file.downloadProgress?.status === DownloadStatus.Busy,
      ),
    );
    return busy.length;
  }

  @Action(AddToDownload)
  addToDownload(
    ctx: StateContext<DownloadStateModel>,
    { scanDownload, fileDownload }: AddToDownload,
  ) {
    const downloads = ctx.getState().downloads;

    const scan: ScanDownload = downloads.find(
      (scan) => scan.scandataModel.id === scanDownload.scandataModel.id,
    ) ?? { ...scanDownload, ...{ files: [] } };

    const hasFile = scan.files.find(
      (file) => file.scandataFile.name === fileDownload.scandataFile.name,
    );

    if (hasFile) return;

    const updateScan = { ...scan, files: [...scan.files, fileDownload] };

    ctx.setState(patch<DownloadStateModel>({ downloads: updateScanDownload(updateScan) }));
  }

  @Action(PatchFileDownload)
  patchFileDownload(ctx: StateContext<DownloadStateModel>, { fileDownload }: PatchFileDownload) {
    ctx.setState(patch<DownloadStateModel>({ downloads: updateFileDownload(fileDownload) }));
  }

  @Action(ClearAllDownloads)
  clearAllDownloads(ctx: StateContext<DownloadStateModel>) {
    ctx.patchState({ downloads: [] });
  }

  @Action(ClearCompletedDownloads)
  clearCompletedDownloads(ctx: StateContext<DownloadStateModel>) {
    const downloads = ctx.getState().downloads;
    const incompleteDownloads = downloads
      .map((scan) => {
        scan.files = scan.files.filter(
          (file) =>
            file.downloadProgress?.status === DownloadStatus.Pending ||
            file.downloadProgress?.status === DownloadStatus.Busy,
        );

        return scan;
      })
      .filter((scan) => scan.files.length > 0);

    ctx.setState(patch<DownloadStateModel>({ downloads: incompleteDownloads }));
  }
}

function updateScanDownload(scanDownload: ScanDownload): StateOperator<ScanDownload[]> {
  return (existing: ExistingState<ScanDownload[]>) => {
    const update = existing.filter(
      (scan) => scan.scandataModel.id !== scanDownload.scandataModel.id,
    );

    update.push(scanDownload);
    return update;
  };
}

function updateFileDownload(fileDownload: FileDownload): StateOperator<ScanDownload[]> {
  return (existing: ExistingState<ScanDownload[]>) => {
    const update = [...existing];

    const scan = update.find((item) =>
      item.files.find((file) => file.scandataModelId === fileDownload.scandataModelId),
    );
    if (isNil(scan)) return update;

    scan.files = scan.files.map((file) => {
      if (file.scandataFile.name !== fileDownload.scandataFile.name) return file;
      return fileDownload;
    });

    return update;
  };
}

function getProgress(files: FileDownload[]): DownloadProgress | null {
  const hasFiles = files.length > 0;
  if (!hasFiles) return null;

  const downloadingAll = files.every((file) => isDefined(file.downloadProgress));
  if (!downloadingAll) return null;

  if (files.length === 1) return files[0].downloadProgress ?? null;

  const hasIncompleteDownloads =
    files.filter(
      (file) =>
        file.downloadProgress?.status === DownloadStatus.Pending ||
        file.downloadProgress?.status === DownloadStatus.Busy,
    ).length > 0;

  const percentageComplete = hasIncompleteDownloads ? getPercentageComplete(files) : 100;

  const hasFailure = files.some((file) => file.downloadProgress?.status === DownloadStatus.Failed);

  const status = hasIncompleteDownloads
    ? DownloadStatus.Busy
    : hasFailure
      ? DownloadStatus.Failed
      : DownloadStatus.Complete;

  const downloadProgress: DownloadProgress = {
    status,
    percentageComplete,
    content: null,
  };

  return downloadProgress;
}

function getPercentageComplete(files: FileDownload[]) {
  const totalBytes = files.reduce((total, file) => {
    return total + file.scandataFile.size;
  }, 0);

  const completedBytes = files.reduce((total, file) => {
    const progress = file.downloadProgress?.percentageComplete ?? 0;
    if (progress === 0) return total;

    const fileCompletedBytes = (file.scandataFile.size / 100) * progress;

    return total + fileCompletedBytes;
  }, 0);

  const percentageComplete = Number(
    totalBytes ? ((100 * completedBytes) / totalBytes).toFixed(1) : 0,
  );

  return percentageComplete;
}
