import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'area',
  standalone: true,
})
export class AreaPipe implements PipeTransform {
  transform(sizeInSquareMetres: number, precision = 2): string {
    if (sizeInSquareMetres === null || isNaN(sizeInSquareMetres) || sizeInSquareMetres <= 0) {
      return '';
    }

    if (precision === null || isNaN(precision) || precision <= 0) {
      return '';
    }

    if (sizeInSquareMetres < 1) return '< 1 m²';

    const rounded = parseFloat(sizeInSquareMetres.toPrecision(precision));

    if (rounded < 1000000) {
      return `∼ ${rounded.toFixed(0)} m²`;
    } else {
      return `∼ ${parseFloat((sizeInSquareMetres * 0.000001).toPrecision(precision))} km²`;
    }
  }
}
