import { Filters, PageInfo, SortInfo } from './scandata-query.models';
import { ScandataModel } from './scandata.models';

export class UpdateScandata {
  static readonly type = '[Scandata] UpdateScandata';
  constructor(public readonly scandata: ScandataModel[]) {}
}

export class SetScandata {
  static readonly type = '[Scandata] SetScandata';
  constructor(public readonly scandata: ScandataModel[]) {}
}

export class ClearScandata {
  static readonly type = '[Scandata] ClearScandata';
}

export class SelectScandataModel {
  static readonly type = '[Scandata] SelectScandataModel';
  constructor(public readonly scandataModelId: string) {}
}

export class UnselectScandataModel {
  static readonly type = '[Scandata] UnselectScandataModel';
  constructor(public readonly scandataModelId: string) {}
}

export class SelectOnly {
  static readonly type = '[Scandata] SelectOnly';
  constructor(public readonly scandataModelIds: string[]) {}
}

export class SetSelected {
  static readonly type = '[ScandataSelect] SetSelected';
  constructor(public readonly scans: ScandataModel[]) {}
}

export class UnselectAllScandataModels {
  static readonly type = '[Scandata] UnselectAllScandataModels';
}

export class PatchScandataModel {
  static readonly type = '[Scandata] PatchScandataModel';
  constructor(public readonly scandataModel: Partial<ScandataModel>) {}
}

export class PatchScandataModels {
  static readonly type = '[Scandata] PatchScandataModels';
  constructor(public readonly scandata: Partial<ScandataModel>[]) {}
}

export class AddScan {
  static readonly type = '[Scandata] AddScan';
  constructor(public readonly scan: ScandataModel) {}
}

export class RemoveScandataModel {
  static readonly type = '[Scandata] RemoveScandataModel';
  constructor(public readonly scandataModelId: string) {}
}

export class SetTextFilter {
  static readonly type = '[Scandata] setTextFilter';
  constructor(public readonly textFilter: string | undefined) {}
}

export class SetSortInfo {
  static readonly type = '[Scandata] SetSortInfo';
  constructor(public readonly sortInfo: SortInfo) {}
}

export class SetPageInfo {
  static readonly type = '[Scandata] SetPageInfo';
  constructor(public readonly pageInfo: PageInfo) {}
}

export class SetFilters {
  static readonly type = '[Scandata] SetFilters';
  constructor(public readonly filters: Filters) {}
}

export class ClearFilters {
  static readonly type = '[Scandata] ClearFilters';
}

export class SetIsLoading {
  static readonly type = '[Scandata] SetIsLoading';
  constructor(public readonly isLoading: boolean) {}
}
