<!-- offscreen rendering -->
<div class="absolute top-[-9999px] left-[-9999px] flex flex-wrap w-[600px]">
  @if (contentVisible()) {
    @for (offscreenChip of offscreenChips(); track $index) {
      <div
        #offscreenChipElement
        class="max-w-40 px-5 rounded-2xl whitespace-nowrap overflow-hidden text-ellipsis text-[0.8125rem] font-semibold leading-[32px]"
      >
        {{ offscreenChip }}
      </div>
    }
    <div
      #offscreenSummaryElement
      class="max-w-40 px-5 rounded-2xl whitespace-nowrap overflow-hidden text-ellipsis text-[0.8125rem] font-semibold leading-[32px]"
    >
      {{ offscreenSummary() }}
    </div>
  }
</div>

<!-- onscreen rendering -->
<ul
  class="absolute flex flex-wrap items-center h-full w-full py-1 gap-2 my-auto"
  (click)="chipContainerClick($event)"
>
  @for (chip of visibleChips(); track $index) {
    <li
      class="max-w-40 h-8 px-5 rounded-2xl whitespace-nowrap overflow-hidden text-ellipsis text-[0.8125rem] font-semibold leading-[32px] bg-gray-1 hover:bg-gray-0"
    >
      {{ chip }}
    </li>
  }

  @if (showSummary()) {
    <li
      [modus-tooltip]="summaryTooltip()"
      [tooltipClass]="['!whitespace-normal', '!max-w-60']"
      class="max-w-40 h-8 px-5 rounded-2xl whitespace-nowrap overflow-hidden text-ellipsis text-[0.8125rem] font-semibold leading-[32px] bg-gray-1 hover:bg-gray-0"
    >
      {{ summaryChip() }}
    </li>
  }
</ul>
