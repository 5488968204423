import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  Self,
  computed,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { ModusButtonModule } from '@trimble-gcs/modus';
import { filter, map, switchMap } from 'rxjs';
import { ClassificationScheme } from '../../classification/classification-scheme.model';
import { ClassificationService } from '../../classification/classification.service';
import { DialogComponent } from '../../dialog/dialog.component';
import { CANCEL_BUTTON, DialogData } from '../../dialog/dialog.model';
import { DialogService } from '../../dialog/dialog.service';
import { ClearError } from '../../error-handling/error.actions';
import { ErrorState } from '../../error-handling/error.state';
import { LoadingService } from '../../loading/loading.service';
import { injectLogger } from '../../logging/logger-injection';
import { downloadBlob } from '../../utils/download-blob';
import { ClassificationFormState } from './classification-form-state';
import { ClassificationTableComponent } from './classification-table/classification-table.component';

@UntilDestroy()
@Component({
  selector: 'sd-config-classification',
  standalone: true,
  imports: [CommonModule, ClassificationTableComponent, ModusButtonModule, MatProgressBarModule],
  providers: [ClassificationFormState],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './classification.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationComponent implements OnDestroy {
  private logger = injectLogger('ClassificationComponent');

  private loadError = this.store.selectSignal(ErrorState.hasError('classificationLoadError'));
  private saveError = this.store.selectSignal(ErrorState.hasError('classificationSaveError'));
  private resetError = this.store.selectSignal(ErrorState.hasError('classificationResetError'));
  private importError = this.store.selectSignal(ErrorState.hasError('classificationImportError'));

  hasError = computed(() => {
    return this.loadError() || this.saveError() || this.resetError() || this.importError();
  });

  errorMessage = computed(() => {
    if (this.loadError()) return 'Error loading classifications.';
    if (this.saveError()) return 'Error saving classifications.';
    if (this.resetError()) return 'Error resetting classifications.';
    if (this.importError()) return 'Error importing classifications.';
    return '';
  });

  isLoading = toSignal(this.loadingService.isLoading$(this), { initialValue: false });
  disableSave = computed(() => this.isLoading() || !this.state.valid());

  classifications = signal<ClassificationScheme[]>([]);

  private importDialogRef: MatDialogRef<DialogComponent> | null = null;
  private resetDialogRef: MatDialogRef<DialogComponent> | null = null;

  constructor(
    private loadingService: LoadingService,
    private classificationService: ClassificationService,
    private store: Store,
    private dialogService: DialogService,
    @Self() public state: ClassificationFormState,
  ) {
    this.loadClassifications();
  }

  ngOnDestroy(): void {
    this.importDialogRef?.close();
    this.resetDialogRef?.close();
  }

  reset() {
    const dialogData = new DialogData(
      'Reset Classifications',
      'Are you sure you want to reset classifications to the default?',
      { text: 'Reset', color: 'danger' },
      CANCEL_BUTTON,
    );

    this.resetDialogRef = this.dialogService.showMessage(dialogData);

    this.resetDialogRef
      .afterClosed()
      .pipe(
        filter((confirmed) => confirmed ?? false),
        switchMap(() => {
          return this.loadingService.loadFrom<ClassificationScheme[]>(
            this.classificationService.resetClassificationSchemes(),
            this,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (data) => this.classifications.set(data),
        error: (err) => this.logger.error('Classifications Reset Error', {}, err),
      });
  }

  export() {
    const classifications = this.classifications();
    const content = JSON.stringify(classifications, null, 2);
    const blob = new Blob([content], { type: 'application/json' });
    downloadBlob(blob, 'classifications.json');
  }

  cancel() {
    this.classifications.update((data) => [...data]);
  }

  save() {
    this.loadingService
      .loadFrom(this.classificationService.saveClassificationSchemes(this.state.value()), this)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (data) => this.classifications.set(data),
        error: (err) => this.logger.error('Classifications Save Error', {}, err),
      });
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files?.item(0);
    input.value = '';
    if (file) this.importFile(file);
  }

  dismissAlert() {
    this.store.dispatch([
      new ClearError('classificationLoadError'),
      new ClearError('classificationSaveError'),
      new ClearError('classificationResetError'),
      new ClearError('classificationImportError'),
    ]);
  }

  private loadClassifications() {
    this.loadingService
      .loadFrom(this.classificationService.getClassificationSchemes(), this)
      .pipe(untilDestroyed(this))
      .subscribe((data) => this.classifications.set(data));
  }

  private importFile(file: File) {
    this.classificationService
      .readAndParseFile(file)
      .pipe(
        switchMap((classifications) => this.confirmImport(classifications)),
        switchMap((classifications) => {
          return this.loadingService.loadFrom<ClassificationScheme[]>(
            this.classificationService.saveClassificationSchemes(classifications),
            this,
          );
        }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (data) => this.classifications.set(data),
        error: (err) => this.logger.error('Classifications Import Error', {}, err),
      });
  }

  private confirmImport(classifications: ClassificationScheme[]) {
    const dialogData = new DialogData(
      'Import Classifications',
      `Are you sure you want to import ${classifications.length} classification${
        classifications.length > 1 ? 's' : ''
      }?`,
      { text: 'Import', color: 'primary' },
      CANCEL_BUTTON,
    );

    this.importDialogRef = this.dialogService.showMessage(dialogData);

    return this.importDialogRef.afterClosed().pipe(
      filter((confirmed) => confirmed ?? false),
      map(() => classifications),
    );
  }
}
