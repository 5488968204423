<div class="flex flex-col h-full">
  <!-- header -->
  <div mat-dialog-title>
    <div class="flex flex-1 flex-wrap items-center font-bold">Upload File</div>

    <button
      id="import-dialog-close"
      modus-icon-button
      modus-tooltip="Minimize"
      (click)="minimizeClick()"
      [disabled]="disableMinimize()"
    >
      <md-icon>remove</md-icon>
    </button>

    <button
      id="import-dialog-close"
      modus-icon-button
      modus-tooltip="Close"
      (click)="cancelClick()"
      [disabled]="disableCancel()"
      class="ml-4"
    >
      <md-icon>close</md-icon>
    </button>
  </div>

  <!-- content -->
  <mat-dialog-content class="!pb-0 !border-0 overflow-hidden">
    @switch (importStatus()) {
      @case (status.NotStarted) {
        @if (maxFilesReached()) {
          <modus-alert class="w-full" type="warning" [message]="maxFilesMessage"></modus-alert>
        } @else {
          <sd-local-file-picker
            class="flex min-h-60 h-60"
            (filesPicked)="addFiles($event)"
          ></sd-local-file-picker>
        }
      }
      @case (status.Busy) {
        <div class="flex flex-col items-center justify-center text-trimble-gray">
          <img src="/assets/upload-in-progress.png" />
          <div class="text-lg font-semibold mt-2">Upload in Progress</div>
          <div class="text-center text-sm my-2">
            Do not close this browser tab while upload is in progress.
          </div>
        </div>
      }
      @case (status.Error) {
        <modus-alert
          class="w-full"
          type="error"
          message="There was an error during upload."
        ></modus-alert>
      }
      @case (status.Completed) {
        <modus-alert class="w-full" type="success" message="Upload completed."></modus-alert>
      }
    }

    <sd-import-file-list
      #uploadFilesControl
      class="flex"
      [importFiles]="importFiles()"
      [disableRemove]="importStarted()"
      [showStatus]="importStarted()"
      (removeFiles)="removeFiles($event)"
    ></sd-import-file-list>
  </mat-dialog-content>

  <!-- actions -->
  <mat-dialog-actions class="!pt-0 !mb-2 !border-0">
    <div class="flex w-full items-center justify-end h-8 text-small italic text-gray-8">
      @if (remainingTime(); as remainingTime) {
        Estimated time remaining: {{ remainingTime }}
      }
    </div>
    <div class="flex w-full items-center">
      <modus-switch
        class="ml-4 select-none"
        label="Combine files into a single package"
        [formControl]="combineToSingleScanControl"
      ></modus-switch>

      <div class="flex grow justify-end">
        <button
          id="import-dialog-cancel"
          modus-button
          color="secondary"
          (click)="cancelClick()"
          [disabled]="disableCancel()"
        >
          {{ cancelButtonText() }}
        </button>
        <button
          id="import-dialog-import"
          modus-flat-button
          color="primary"
          class="ml-4"
          (click)="importClick()"
          [disabled]="disableImport()"
        >
          Upload
        </button>
      </div>
    </div>
  </mat-dialog-actions>
</div>
