import { ComponentType, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from './dialog.component';
import { DialogData } from './dialog.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly defaultDialogConfig: MatDialogConfig = {
    minWidth: 220,
    autoFocus: false,
    scrollStrategy: this.scrollStrategyOptions.noop(),
  };

  constructor(
    private dialog: MatDialog,
    private scrollStrategyOptions: ScrollStrategyOptions,
  ) {}

  showMessage(data: DialogData, config?: MatDialogConfig): MatDialogRef<DialogComponent, boolean> {
    const dialogRef = this.dialog.open<DialogComponent, DialogData, boolean>(DialogComponent, {
      ...this.defaultDialogConfig,
      ...config,
      data,
    });

    return dialogRef;
  }

  showComponent<T, D = any, R = any>(
    component: ComponentType<T>,
    config?: MatDialogConfig<D>,
  ): MatDialogRef<T, R> {
    const dialogRef = this.dialog.open<T, D, R>(component, {
      ...this.defaultDialogConfig,
      ...config,
    });

    return dialogRef;
  }
}
