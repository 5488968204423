import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { SetInfoView, SetPreviewVisible, SetView } from './options-panel.actions';

export enum OptionsPanelView {
  None = 'None',
  DownloadStatus = 'DownloadStatus',
  ListFilters = 'ListFilters',
  SelectedDetails = 'SelectedDetails',
  ClassificationEdit = 'ClassificationEdit',
}

export enum OptionsPanelInfoView {
  Properties = 'Properties',
  Tagging = 'Tagging',
  Classification = 'Classification',
}

export interface OptionsPanelStateModel {
  view: OptionsPanelView;
  infoView: OptionsPanelInfoView;
  previewVisible: boolean;
}

const defaultState: OptionsPanelStateModel = {
  view: OptionsPanelView.None,
  infoView: OptionsPanelInfoView.Properties,
  previewVisible: true,
};

@State<OptionsPanelStateModel>({
  name: 'optionsPanelState',
  defaults: defaultState,
})
@Injectable()
export class OptionsPanelState {
  @Selector() static view(state: OptionsPanelStateModel) {
    return state.view ?? OptionsPanelView.None;
  }

  @Selector() static infoView(state: OptionsPanelStateModel) {
    return state.infoView ?? OptionsPanelInfoView.Properties;
  }

  @Selector() static previewVisible(state: OptionsPanelStateModel) {
    return state.previewVisible;
  }

  @Action(SetView) setView(ctx: StateContext<OptionsPanelStateModel>, action: SetView) {
    let setView = action.view;

    if (setView === ctx.getState().view && action.withToggle) {
      setView = OptionsPanelView.None;
    }

    ctx.patchState({ view: setView });
  }

  @Action(SetInfoView) setInfoView(ctx: StateContext<OptionsPanelStateModel>, action: SetInfoView) {
    ctx.patchState({ infoView: action.infoView });
  }

  @Action(SetPreviewVisible) setPreviewVisible(
    ctx: StateContext<OptionsPanelStateModel>,
    action: SetPreviewVisible,
  ) {
    ctx.patchState({ previewVisible: action.previewVisible });
  }
}
