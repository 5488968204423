import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  computed,
  effect,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusAutocomplete,
  ModusAutocompleteModule,
  ModusButtonModule,
  ModusIconModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { filter, map, switchMap } from 'rxjs';
import { DialogComponent } from '../../../../dialog/dialog.component';
import { DialogData } from '../../../../dialog/dialog.model';
import { DialogService } from '../../../../dialog/dialog.service';
import { ScandataModel, UpdateScandataModel } from '../../../../scandata/scandata.models';
import { ScandataService } from '../../../../scandata/scandata.service';
import { MAX_TAG_LENGTH, TagService } from '../../../../tag/tag.service';
import { NotWhitespaceStringValidator } from '../../../../utils/not-whitespace-string-validator';

@Component({
  selector: 'sd-tagging',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    ModusAutocompleteModule,
    MatProgressBarModule,
    ScrollingModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './tagging.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaggingComponent implements OnDestroy {
  private autocomplete = viewChild.required<ModusAutocomplete<string>>('autocomplete');

  private projectTags = toSignal(this.tagService.getTags(), { initialValue: [] });
  private onAnyScan = signal<string[]>([]);
  private onEveryScan = signal<string[]>([]);

  private addableTags = this.getAddableTags();
  private removedTags = signal<string[]>([]);

  scandataModels = input.required<ScandataModel[]>();
  closeClicked = output();

  isSaving = signal(false);
  addedTags = signal<string[]>([]);

  existingTags = computed(() => getTagsExcluding(this.onAnyScan(), this.removedTags()));
  hasExistingTags = computed(() => this.existingTags().length > 0);

  filteredTags = this.getFilteredTags();
  errorText = this.getErrorText();

  saveDisabled = computed(
    () => this.isSaving() || (this.addedTags().length === 0 && this.removedTags().length === 0),
  );

  tagSelector = new FormControl<string | null>(null, {
    validators: [NotWhitespaceStringValidator, Validators.maxLength(MAX_TAG_LENGTH)],
    updateOn: 'change',
  });

  private tagSelectorValue = toSignal(this.tagSelector.valueChanges, { initialValue: null });
  private tagSelectorStatus = toSignal(this.tagSelector.statusChanges);
  private tagInput = toSignal(this.observeAutocompleteInput(), { equal: () => false });

  private errorDialogRef: MatDialogRef<DialogComponent> | null = null;

  @HostBinding('class') class = 'flex flex-col h-full';

  constructor(
    private tagService: TagService,
    private scandataService: ScandataService,
    private dialogService: DialogService,
  ) {
    this.createScandataModelsEffect();
    this.createTagSelectorTouchedEffect();
    this.createTagInputEffect();
  }

  ngOnDestroy(): void {
    this.errorDialogRef?.close();
  }

  removeExisting(tag: string) {
    this.removedTags.update((value) => [...value, tag]);
  }

  removeAdded(tag: string) {
    this.addedTags.update((added) => {
      const index = added.indexOf(tag);
      if (index >= 0) {
        added.splice(index, 1);
      }
      return [...added];
    });
  }

  addTag(tag: string) {
    if (this.tagSelector.invalid || isNil(tag)) return;

    // if the tag was on every scan and subsequently removed
    // we should add it back by removing it from the removedTags
    if (this.onEveryScan().includes(tag)) {
      this.undoRemove(tag);
    } else {
      this.addedTags.update((value) => [...value, tag]);
    }

    this.tagSelector.reset();
  }

  save() {
    this.setFormSaving(true);

    const updates = this.scandataModels().map((scan) => {
      const tags = isNil(scan.tags) ? [] : getTagsExcluding(scan.tags, this.removedTags());
      const uniqueTags = getUniqueTags([tags, this.addedTags()]);

      return <UpdateScandataModel & { pointcloudId: string }>{
        pointcloudId: scan.id,
        tags: uniqueTags,
      };
    });

    return this.scandataService.updateScandataModels(updates).subscribe({
      next: (results) => {
        const errors = this.scandataModels().filter((model) => {
          const hasError = results.some(
            (update) =>
              update.updateScandataModel.pointcloudId === model.id && isDefined(update.error),
          );
          return hasError;
        });

        if (errors.length === 0) return this.closeClicked.emit();

        this.showSaveErrors(errors);
        this.setFormSaving(false);
      },
    });
  }

  private createScandataModelsEffect() {
    effect(
      () => {
        const scans = this.scandataModels();
        const onAnyScan = getUniqueTags(scans.flatMap((scan) => scan.tags).filter(isDefined));
        const onEveryScan = onAnyScan.filter((tag) =>
          scans.every((scan) => scan.tags?.includes(tag)),
        );

        this.onAnyScan.set(onAnyScan);
        this.onEveryScan.set(onEveryScan);

        this.addedTags.update((added) => getTagsExcluding(added, onEveryScan));
        this.removedTags.update((removed) => removed.filter((tag) => onAnyScan.includes(tag)));
      },
      { allowSignalWrites: true },
    );
  }

  private createTagSelectorTouchedEffect() {
    effect(() => {
      const status = this.tagSelectorStatus();
      if (status === 'INVALID' && this.tagSelector.dirty) {
        this.tagSelector.markAsTouched();
      }
    });
  }

  private createTagInputEffect() {
    effect(
      () => {
        const tag = this.tagInput();
        if (isNil(tag)) return;

        this.addTag(tag);
        this.autocomplete().closePanel();
      },
      { allowSignalWrites: true },
    );
  }

  private getAddableTags() {
    return computed(() => {
      const visibleOnEveryScan = getTagsExcluding(this.onEveryScan(), this.removedTags());
      const exclVisibleOnEveryScan = getTagsExcluding(this.projectTags(), visibleOnEveryScan);
      const addable = getTagsExcluding(exclVisibleOnEveryScan, this.addedTags());
      return addable;
    });
  }

  private getFilteredTags() {
    return computed(() => {
      const availableTags = this.addableTags();
      const filter = this.tagSelectorValue();

      if (isNil(filter)) return availableTags;

      const filterValue = filter.toLowerCase();
      return availableTags.filter((tag) => tag.toLowerCase().includes(filterValue));
    });
  }

  private getErrorText() {
    return computed(() => {
      const status = this.tagSelectorStatus();

      return status === 'INVALID' &&
        this.tagSelector.dirty &&
        this.tagSelector.hasError('maxlength')
        ? `Maximum ${MAX_TAG_LENGTH} characters allowed.`
        : '';
    });
  }

  private undoRemove(tag: string) {
    this.removedTags.update((removed) => {
      const index = removed.indexOf(tag);
      if (index >= 0) {
        removed.splice(index, 1);
      }
      return [...removed];
    });
  }

  private observeAutocompleteInput() {
    const tagEntered$ = toObservable(this.autocomplete).pipe(
      switchMap((autocomplete) => autocomplete.inputKeydown$),
      filter((event) => event.key === 'Enter'),
      filter(() => isNil(this.autocomplete().activeOption)),
      filter(() => this.tagSelector.dirty && this.tagSelector.valid),
      map(() => {
        const value = this.tagSelector.value as string;
        const tag =
          this.projectTags().find((tag) => tag.toLowerCase() === value.toLowerCase()) ?? value;
        return tag;
      }),
      filter((tag) => {
        const inAdded = this.addedTags().find(
          (added) => added.toLowerCase() === tag.toLocaleLowerCase(),
        );
        return !inAdded;
      }),
    );

    return tagEntered$;
  }

  private setFormSaving(isSaving: boolean) {
    if (isSaving) {
      this.tagSelector.disable({ emitEvent: false });
    } else {
      this.tagSelector.enable({ emitEvent: false });
    }

    this.isSaving.set(isSaving);
  }

  private showSaveErrors(failedModels: ScandataModel[]) {
    const message =
      `The following scan${failedModels.length > 1 ? 's' : ''} failed to update:\n\n` +
      failedModels.map((model) => model.name).join('\n');

    const dialogData = new DialogData('Save Error', message, {
      text: 'Dismiss',
      color: 'danger',
    });

    this.errorDialogRef = this.dialogService.showMessage(dialogData);
  }
}

function getTagsExcluding(array: string[], excludeArray: string[]) {
  return array.filter((value) => {
    return !excludeArray.includes(value);
  });
}

function getUniqueTags(array: string[]): string[];
function getUniqueTags(arrays: string[][]): string[];
function getUniqueTags(arrays: string[] | string[][]): string[] {
  return [...new Set([...arrays.flatMap((val) => val)])];
}
