import { FlatButtonColor, StrokedButtonColor } from '@trimble-gcs/modus';

export class DialogData {
  constructor(
    public title: string,
    public message: string,
    public okButton: OkDialogButton = OK_BUTTON,
    public cancelButton: CancelDialogButton | null = null,
  ) {}
}

export type OkDialogButton = {
  text: string;
  color: FlatButtonColor;
};

export type CancelDialogButton = {
  text: string;
  color: StrokedButtonColor;
};

export const OK_BUTTON: OkDialogButton = {
  text: 'Ok',
  color: 'primary',
};

export const CANCEL_BUTTON: CancelDialogButton = {
  text: 'Cancel',
  color: 'secondary',
};
