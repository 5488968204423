import { Observable, Subscriber } from 'rxjs';

export type ResizeObservation = {
  observer: ResizeObserver;
  entries: ResizeObserverEntry[];
};

export function getResizeObservable(
  target: Element,
  options?: ResizeObserverOptions,
): Observable<ResizeObservation> {
  return new Observable((subscriber: Subscriber<ResizeObservation>) => {
    const resizeObserver = new ResizeObserver((entries, observer) =>
      subscriber.next({ observer, entries }),
    );

    resizeObserver.observe(target, options);

    return () => resizeObserver.disconnect();
  });
}
