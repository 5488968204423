import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ModusButtonModule, ModusIconModule, ModusMenuModule } from '@trimble-gcs/modus';
import { SortInfo } from '../../../scandata/scandata-query.models';
import { ScandataModel } from '../../../scandata/scandata.models';

export interface SortOption {
  sortableProperty: SortableProperty;
  direction: 'asc' | 'desc' | '';
}

export interface SortableProperty {
  property: keyof ScandataModel;
  displayName: string;
}

const sortableProperties: SortableProperty[] = [
  { property: 'name', displayName: 'Name' },
  { property: 'uploadedDate', displayName: 'Date' },
];

@UntilDestroy()
@Component({
  selector: 'sd-map-list-sort-menu',
  standalone: true,
  imports: [ModusIconModule, ModusButtonModule, ModusMenuModule],
  templateUrl: './map-list-sort-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapListSortMenuComponent {
  sortInfo = input.required<SortInfo>();
  sortChanged = output<SortInfo>();

  menuItems = computed(() => this.getMenuItems(this.sortInfo()));

  onSortClick(sortOption: SortOption) {
    this.sortChanged.emit({
      sortBy: sortOption.sortableProperty.property,
      sortDirection: sortOption.direction === 'asc' ? 'desc' : 'asc',
    });
  }

  private getMenuItems(activeSort: SortInfo): SortOption[] {
    return sortableProperties.map((sortableProperty) => {
      const direction =
        sortableProperty.property === activeSort.sortBy ? activeSort.sortDirection : '';

      return {
        sortableProperty,
        direction,
      } satisfies SortOption;
    });
  }
}
