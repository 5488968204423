import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  computed,
  effect,
  input,
  output,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusDatePickerModule,
  ModusFormFieldModule,
  ModusInputModule,
  ModusSelectModule,
  toIsoDateString,
} from '@trimble-gcs/modus';
import { DashedIfEmptyPipe, FileSizePipe, PointCountPipe } from '@trimble-gcs/ngx-common';
import { finalize, takeWhile } from 'rxjs';
import { ClearError } from '../../../../error-handling/error.actions';
import { ErrorState } from '../../../../error-handling/error.state';
import { LabelValueComponent } from '../../../../label-value/label-value.component';
import { injectLogger } from '../../../../logging/logger-injection';
import { AreaPipe } from '../../../../pipes/area.pipe';
import { ScandataModel, UpdateScandataModel } from '../../../../scandata/scandata.models';
import { ScandataService } from '../../../../scandata/scandata.service';
import { MaxDateValidator } from '../../../../utils/max-date-validator';
import { NotWhitespaceStringValidator } from '../../../../utils/not-whitespace-string-validator';

@UntilDestroy()
@Component({
  selector: 'sd-properties',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusDatePickerModule,
    ModusFormFieldModule,
    ModusInputModule,
    ModusSelectModule,
    LabelValueComponent,
    FileSizePipe,
    PointCountPipe,
    AreaPipe,
    DashedIfEmptyPipe,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './properties.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertiesComponent implements OnDestroy {
  scandataModel = input.required<ScandataModel>();
  readonly = input(false);
  showName = input(true);
  showTags = input(false);

  saving = output<boolean>();

  formGroup = this.createFormGroup();
  maxCaptureDate = toIsoDateString(new Date());
  locationSummary = this.getLocationSummary();

  scanDetailsSaveError = this.store.selectSignal(ErrorState.hasError('scanDetailsSaveError'));

  private logger = injectLogger('PropertiesComponent');
  private destroy = false;

  constructor(
    private scandataService: ScandataService,
    private store: Store,
  ) {
    effect(() => this.initForm(this.scandataModel()));
  }

  ngOnDestroy(): void {
    this.destroy = true;
    this.store.dispatch(new ClearError('scanDetailsSaveError'));
  }

  saveChange(control: FormControl) {
    if (control.pristine || control.invalid || control.disabled || this.readonly()) return;

    const controls = this.formGroup.controls;
    const value = this.formGroup.value;
    const model = this.scandataModel();

    switch (control) {
      case controls.name:
        this.saveScandataModel(controls.name, { name: value.name }, model.name);
        break;

      case controls.captureDate:
        this.saveScandataModel(
          controls.captureDate,
          { captureDatetimeUtc: value.captureDate ?? '' },
          model.captureDatetimeUtc,
        );
        break;

      case controls.scannerType:
        this.saveScandataModel(
          controls.scannerType,
          { scannerType: value.scannerType ?? '' },
          model.scannerType,
        );
        break;

      case controls.notes:
        this.saveScandataModel(controls.notes, { notes: value.notes ?? '' }, model.notes);
        break;
    }
  }

  private createFormGroup() {
    return new FormGroup({
      name: new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, NotWhitespaceStringValidator],
      }),
      captureDate: new FormControl<Date | null>(null, {
        validators: MaxDateValidator(() => this.maxCaptureDate),
      }),
      scannerType: new FormControl<string | null>(null, {
        validators: NotWhitespaceStringValidator,
      }),
      notes: new FormControl<string | null>(null),
    });
  }

  private getLocationSummary() {
    return computed(() => {
      const location = this.scandataModel()?.location;

      if (isNil(location)) return null;

      const displayValues = [location.country, location.stateName, location.city, location.zip];
      const summary = displayValues.filter((value) => value?.length > 0).join(', ');

      return summary.length > 0 ? summary : null;
    });
  }

  private initForm(model: ScandataModel) {
    this.formGroup.setValue(
      {
        name: model.name,
        captureDate: model.captureDatetimeUtc ?? null,
        scannerType: model.scannerType ?? null,
        notes: model.notes ?? null,
      },
      { emitEvent: false },
    );

    const uploadedDate = model.uploadedDate;
    if (isDefined(uploadedDate)) {
      this.maxCaptureDate = toIsoDateString(uploadedDate);
    }

    // If a new model is passed to the input we want the form to start pristine again.
    this.formGroup.markAsPristine();

    this.setFormSaving(false);
  }

  private setFormSaving(isSaving: boolean) {
    if (isSaving) {
      this.formGroup.disable({ emitEvent: false });
    } else {
      this.formGroup.enable({ emitEvent: false });
    }

    this.saving.emit(isSaving);
  }

  private saveScandataModel<T>(
    control: FormControl<T>,
    updateScandataModel: UpdateScandataModel,
    oldValue: T,
  ) {
    this.setFormSaving(true);

    const pointcloudId = this.scandataModel().id;
    this.scandataService
      .updateScandataModel(pointcloudId, updateScandataModel)
      .pipe(
        finalize(() => this.setFormSaving(false)),
        // Using takeWhile so that the last emission will complete.
        // Alternatively using takeUntil would cancel the last emission and
        // therefore cancel the http request, resulting in the change not saving.
        takeWhile(() => !this.destroy),
      )
      .subscribe({
        error: (err) => {
          control.reset(oldValue, { emitEvent: false });
          this.logger.error('Save Error', { pointcloudId, ...updateScandataModel }, err);
        },
      });
  }
}
