import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  output,
  signal,
  untracked,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from '@trimble-gcs/common';
import { ModusSwitchModule } from '@trimble-gcs/modus';
import { ColorPickerModule } from 'ngx-color-picker';
import { ModusIconModule } from '../../../../../../libs/modus/src/lib/modus-icon/modus-icon.module';
import { colorHexStripAlpha } from '../../utils/color-converter';
import { Scan3dStyle } from '../models/scan-3d-style';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-styling',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ModusSwitchModule,
    MatSliderModule,
    ColorPickerModule,
    MatIconModule,
    ModusIconModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scan-3d-styling.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dStylingComponent {
  scan3dStyle = input.required<Scan3dStyle>();

  styleChange = output<Scan3dStyle>();

  showExperimental = signal(false);
  showIntensitySettings = computed(() => this.scan3dStyle().showIntensity);

  formGroup = new FormGroup({
    showClassification: new FormControl<boolean>(false, { nonNullable: true }),
    showIntensity: new FormControl<boolean>(false, { nonNullable: true }),
    showEyeDomeLighting: new FormControl<boolean>(false, { nonNullable: true }),
    pointBudget: new FormControl<number>(10000000, { nonNullable: true }),
    pointDensity: new FormControl<number>(1, { nonNullable: true }),
    pointSize: new FormControl<number>(1, { nonNullable: true }),
    intensityOffset: new FormControl<number>(0.5, { nonNullable: true }),
    intensityStartColor: new FormControl<string>('#f70', { nonNullable: true }),
    intensityEndColor: new FormControl<string>('#07f', { nonNullable: true }),
  });

  private formValueChanges = toSignal(this.formGroup.valueChanges);

  constructor() {
    this.createScan3dStyleEffect();
    this.createFormValueChangesEffect();
  }

  showExperimentalClick(event: MouseEvent) {
    if (event.shiftKey && event.altKey) {
      this.showExperimental.set(!this.showExperimental());
    }
  }

  stripAlpha(rgba: string) {
    return colorHexStripAlpha(rgba);
  }

  changeIntensityStartColor(color: string) {
    this.formGroup.patchValue({ intensityStartColor: color });
  }

  changeIntensityEndColor(color: string) {
    this.formGroup.patchValue({ intensityEndColor: color });
  }

  private createScan3dStyleEffect() {
    effect(() => {
      const style = this.scan3dStyle();

      // Using untracked because the MatSlider component
      // propagates the FormControl.setValue()
      // by setting a signal internally.
      untracked(() => {
        this.formGroup.setValue(
          {
            showClassification: style.showClassification,
            showIntensity: style.showIntensity,
            showEyeDomeLighting: style.showEyeDomeLighting,
            pointBudget: style.pointBudget,
            pointDensity: style.pointDensity,
            pointSize: style.pointSize,
            intensityOffset: style.intensityOffset,
            intensityStartColor: style.intensityStartColor,
            intensityEndColor: style.intensityEndColor,
          },
          { emitEvent: false },
        );
      });
    });
  }

  private createFormValueChangesEffect() {
    effect(() => {
      const changes = this.formValueChanges();

      /**
       * The initial signal value will be undefined, because a signal always
       * contains a value similiar to a BehaviourSubject.
       *
       * We want to handle form changes from user input only and ignore this
       * initial signal value of undefined.
       */
      if (isNil(changes)) return;

      const style = untracked(() => this.scan3dStyle());
      const mergedStyle: Scan3dStyle = { ...style, ...changes };

      this.styleChange.emit(mergedStyle);
    });
  }
}
