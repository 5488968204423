import { ConnectFile } from 'trimble-connect-workspace-api';
import { isDefined } from '../../../../../libs/common/src';

/**
 * Builds an externalFileId from parts of a ConnectFile.
 * Used and persisted as a property of ScandataModel.
 */
export function getExternalFileId(file: ConnectFile): string | null {
  return file && file.id && file.versionId && file.revision
    ? `${file.id}:${file.versionId}:${file.revision}`
    : null;
}

/**
 * Gets the fileId part from an externalFileId.
 */
export function getFileId(externalFileId: string): string | null {
  const regex = /^([^:]*):?[^:]*:?[^:]*$/;
  const match = externalFileId?.match(regex);
  return match ? match[1] : null;
}

/**
 * Gets the versionId part from an externalFileId.
 */
export function getVersionId(externalFileId: string): string | null {
  const regex = /^[^:]+:([^:]+)(:?:[^:]+)*$/;
  const match = externalFileId?.match(regex);
  return match ? match[1] : null;
}

/**
 * Gets the revision part from an externalFileId.
 */
export function getRevision(externalFileId: string): string | null {
  const regex = /^[^:]+:[^:]+:([^:]+)$/;
  const match = externalFileId?.match(regex);
  return match ? match[1] : null;
}

/**
 * Gets the revision string from an externalFileId.
 */
export function getRevisionString(externalFileId: string): string | null {
  const revision = getRevision(externalFileId);
  return isDefined(revision) ? `v.${revision}` : null;
}
