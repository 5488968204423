import { ChangeDetectionStrategy, Component, input, output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, ofActionDispatched } from '@ngxs/store';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusMenu,
  ModusMenuModule,
  ModusMenuTrigger,
} from '@trimble-gcs/modus';
import { filter } from 'rxjs';
import { CloseSortMenu } from '../../scan-3d.actions';
import { SortMenuItem } from './scan-3d-list-sort-menu.model';

@UntilDestroy()
@Component({
  selector: 'sd-scan-3d-list-sort-menu',
  standalone: true,
  imports: [ModusIconModule, ModusButtonModule, ModusMenuModule],
  templateUrl: './scan-3d-list-sort-menu.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Scan3dListSortMenuComponent {
  @ViewChild(ModusMenu) menu!: ModusMenu;
  @ViewChild(ModusMenuTrigger) menuTrigger!: ModusMenuTrigger;

  menuItems = input.required<SortMenuItem[]>();
  sortClick = output<SortMenuItem>();

  constructor(private actions$: Actions) {
    this.subscribeToCloseSortMenu();
  }

  getIcon(item: SortMenuItem) {
    return item.direction === 'asc' ? 'sort_arrow_up' : 'sort_arrow_down';
  }

  onSortClick(item: SortMenuItem) {
    if (item.active) {
      item.direction = item.direction === 'asc' ? 'desc' : 'asc';
    }

    this.menuItems().forEach((x) => (x.active = x.name === item.name));
    this.sortClick.emit(item);
  }

  private subscribeToCloseSortMenu() {
    this.actions$
      .pipe(
        ofActionDispatched(CloseSortMenu),
        filter(() => this.menu.isOpen),
        untilDestroyed(this),
      )
      .subscribe(() => this.menuTrigger.closeMenu());
  }
}
