export type LoggerType = 'consoleLogger' | 'dataDogLogger';

export enum LogLevel {
  None = 'none',
  Debug = 'debug',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

// reverse map
export const logLevelValues: Record<LogLevel, number> = {
  [LogLevel.None]: 0,
  [LogLevel.Debug]: 1,
  [LogLevel.Info]: 2,
  [LogLevel.Warn]: 3,
  [LogLevel.Error]: 4,
};

export interface ILogger {
  name: string;
  createNamedLogger(name: string): ILogger;
  debug(message: string, context?: object, error?: Error): void;
  info(message: string, context?: object, error?: Error): void;
  warn(message: string, context?: object, error?: Error): void;
  error(message: string, context?: object, error?: Error): void;
}
