import { HttpHandlerFn, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { Endpoints } from '../app-state/app.models';
import { AppState } from '../app-state/app.state';
import { CLIENT_HEADER_KEYS } from './client-header.models';
import { ClientHeaderService } from './client-header.service';

export const clientHeaderInterceptor: HttpInterceptorFn = (
  request: HttpRequest<unknown>,
  next: HttpHandlerFn,
) => {
  if (hasClientHeaders(request) || !requireClientHeaders(request.url)) {
    return next(request);
  }

  const clientHeaderService = inject(ClientHeaderService);
  const requestWithClientHeaders = request.clone({
    setHeaders: clientHeaderService.getClientHeaders(),
  });

  return next(requestWithClientHeaders);
};

function hasClientHeaders(request: HttpRequest<unknown>) {
  return CLIENT_HEADER_KEYS.every((key) => request.headers.has(key));
}

function requireClientHeaders(url: string) {
  const store = inject(Store);
  const endpoints = store.selectSnapshot(AppState.endpoints);
  for (const key in endpoints) {
    const endpoint = endpoints[key as keyof Endpoints];
    if (url.startsWith(endpoint.url)) return endpoint.addClientHeaders ?? false;
  }
  return false;
}
