import { isDefined } from '@trimble-gcs/common';
import { AbstractLogger } from './abstract-logger';
import { LogLevel, LoggerType } from './logger-types';

export class ConsoleLogger extends AbstractLogger {
  protected override level = LogLevel.Debug;

  override get type(): LoggerType {
    return 'consoleLogger';
  }

  constructor(name = 'ConsoleLogger') {
    super(name);
  }

  createNamedLogger(name: string) {
    return new ConsoleLogger(name);
  }

  protected log(level: LogLevel, message: string, context?: object, error?: Error): void {
    if (this.ignore(level)) return;

    const loggerMessage = `[${this.name}]: ${message}`;
    const formatMessage = `%c${loggerMessage}`;
    const args = [...arguments].slice(2).filter(isDefined);

    switch (level) {
      case 'debug':
        return console.debug(formatMessage, 'color: dodgerblue;', ...args);

      case 'info':
        return console.info(formatMessage, 'color: seagreen;', ...args);

      case 'warn':
        return console.warn(loggerMessage, ...args);

      case 'error':
        return console.error(loggerMessage, ...args);

      default:
        return console.log(formatMessage, 'color: dodgerblue;', ...args);
    }
  }
}
