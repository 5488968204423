import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from '@trimble-gcs/common';
import { ModusButtonModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { DownloadProgress, DownloadStatus } from '../download.models';

@UntilDestroy()
@Component({
  selector: 'sd-download-progress',
  standalone: true,
  imports: [
    CommonModule,
    ModusTooltipModule,
    ModusButtonModule,
    ModusIconModule,
    MatProgressSpinnerModule,
  ],
  templateUrl: './download-progress.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadProgressComponent {
  downloadStatus = DownloadStatus;

  downloadProgress = input<DownloadProgress | null | undefined>();
  progressTooltipPrefix = input.required<string>();

  progressTooltip = computed(() => {
    const progress = this.downloadProgress();
    if (isNil(progress)) return '';

    return `${this.progressTooltipPrefix()} ${progress.percentageComplete}%`;
  });
}
