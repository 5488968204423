import { Injectable, inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined } from '@trimble-gcs/common';
import { TierResult, getGPUTier } from 'detect-gpu';
import { catchError, filter, from, map, of, switchMap, take, tap } from 'rxjs';
import { ConnectProject } from 'trimble-connect-workspace-api';
import { AppState } from '../app-state/app.state';
import { ConnectProjectService } from '../connect/connect-project.service';
import { ConnectLicence } from '../connect/models/connect-license';
import { injectLogger } from '../logging/logger-injection';
import { User } from '../user/user.models';
import { UserState } from '../user/user.state';
import { initializeAptrinsic } from './aptrinsic.init';
import { AptrinsicSdk } from './aptrinsic.sdk';
import { GainsightEvents } from './gainisght-events';
import { ProjectInfo } from './gainsight.models';

// https://support.gainsight.com/PX/API_for_Developers/01About/Work_with_Gainsight_PX_Web_SDK

@Injectable({
  providedIn: 'root',
})
export class GainsightService {
  private readonly store = inject(Store);
  private readonly connectProjectService = inject(ConnectProjectService);

  readonly sdk = inject(AptrinsicSdk);
  readonly logger = injectLogger('GainsightService');

  initialize(window: Window, tag: string) {
    initializeAptrinsic(window, tag);
  }

  identifyUser() {
    return this.store.select(UserState.user).pipe(
      filter((user): user is User => isDefined(user)),
      tap((user) => this.sdk.identify(user, { id: user.id })),
      take(1),
    );
  }

  trackProject() {
    return this.store.select(AppState.project).pipe(
      filter(isDefined),
      switchMap((project) => this.getProjectLicence(project)),
      map(([project, licence]) => this.getProjectInfo(project, licence)),
      tap((projectInfo: ProjectInfo) => {
        this.sdk.track(GainsightEvents.projectSelected, projectInfo);
      }),
      take(1),
    );
  }

  detectGpu() {
    return from(getGPUTier()).pipe(
      catchError((error) => {
        this.logger.info('GPU detection failed', {}, error);
        return of({
          tier: 0,
          type: 'WEBGL_UNSUPPORTED',
        } satisfies TierResult);
      }),
      tap((result: TierResult) => {
        this.sdk.track(GainsightEvents.gpuDetected, result);
      }),
    );
  }

  private getProjectLicence(project: ConnectProject) {
    return this.connectProjectService
      .getProjectLicence(project)
      .pipe(map((licence) => [project, licence] as [ConnectProject, ConnectLicence]));
  }

  private getProjectInfo(project: ConnectProject, licence: ConnectLicence) {
    const account = licence.accounts.find((acc) => acc.primary) ?? licence.accounts[0];
    return {
      projectId: project.id,
      projectName: project.name ?? '',
      accountId: account.accountId,
      accountName: account.name,
      accountSource: account.source,
      ecomSource: licence.ecomSource,
    } satisfies ProjectInfo;
  }
}
