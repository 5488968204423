import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  CUSTOM_ELEMENTS_SCHEMA,
  effect,
  ElementRef,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { isDefined, isNil } from '@trimble-gcs/common';
import { ModusCheckboxModule, ModusIconModule, ModusTooltipModule } from '@trimble-gcs/modus';
import { FileSizePipe, PointCountPipe } from '@trimble-gcs/ngx-common';
import { debounceTime, map, shareReplay } from 'rxjs';
import {
  CHIP_HEIGHT,
  CHIP_MIN_WIDTH,
  ChipContainerComponent,
  ChipContainerSize,
} from '../../chip-container/chip-container.component';
import { getRevisionString } from '../../connect/external-file-id-utils';
import { SortInfo } from '../../scandata/scandata-query.models';
import { PointcloudStatus, ScandataModel } from '../../scandata/scandata.models';
import { getResizeObservable } from '../../utils/resize-observable';

interface RowData {
  scan: ScandataModel;
  statusIcon: StatusIcon;
  thumbnail: Thumbnail;
  version?: string;
}

interface StatusIcon {
  icon: string;
  color: string;
  message: string;
}

enum Thumbnail {
  None = 'None',
  Busy = 'Busy',
  Preview = 'Preview',
  Station = 'Station',
}

@Component({
  selector: 'sd-scandata-table',
  standalone: true,
  imports: [
    CommonModule,
    ModusIconModule,
    ModusCheckboxModule,
    ModusTooltipModule,
    MatTableModule,
    MatSortModule,
    FileSizePipe,
    PointCountPipe,
    ChipContainerComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './scandata-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScandataTableComponent implements AfterViewInit {
  private readonly sort = viewChild.required<MatSort>(MatSort);

  private readonly tagsColumnHeader =
    viewChild.required<ElementRef<HTMLElement>>('tagsColumnHeader');

  private readonly chipContainerHeight = 80;

  tagsContainerSize = signal<ChipContainerSize>({ height: 0, width: 0 });
  offscreenTagCount = signal<number>(0);

  dataSource = computed(() => new MatTableDataSource<RowData>(this.data()));

  readonly displayedColumns = [
    'selected',
    'thumbnailUrl',
    'name',
    'status',
    'uploadedBy',
    'uploadedDate',
    'pointCount',
    'fileSize',
    'tags',
  ];

  sortInfo = input.required<SortInfo>();
  lastSelectedId = input<string>();

  data = input.required<RowData[], ScandataModel[]>({
    transform: (value) => {
      return value.map((scan) => ({
        scan,
        statusIcon: this.getIcon(scan),
        thumbnail: this.getThumbnail(scan),
        version: getRevisionString(scan.externalFileId ?? '') ?? undefined,
      }));
    },
  });

  selectionChange = output<ScandataModel[]>();
  chipClick = output<ScandataModel>();
  sortInfoChange = output<SortInfo>();

  isAllSelected = computed(() => {
    const data = this.data();
    return data.length > 0 && data.every((row) => row.scan.selected);
  });

  isSomeSelected = computed(() => {
    const data = this.data();
    return data.some((row) => row.scan.selected) && data.some((row) => !row.scan.selected);
  });

  thumbnail = Thumbnail;

  constructor() {
    this.createSortInfoEffect();
  }

  ngAfterViewInit(): void {
    this.sort().disableClear = true;
    this.subscribeTagsColumnResizeObserver();
  }

  getScanId(index: number, rowData: RowData) {
    return rowData.scan.id;
  }

  toggleAllRows() {
    const data = this.data();
    const selected = !this.isAllSelected();
    const changedScans = data.map((row) => <ScandataModel>{ ...row.scan, selected });

    this.selectionChange.emit(changedScans);
  }

  checkboxKeyDown(rowData: RowData) {
    this.toggleScanSelect(rowData.scan);
  }

  rowClicked(mouseEvent: MouseEvent, rowData: RowData, index: number) {
    if (mouseEvent.shiftKey) return this.toggleRangeSelected(rowData.scan, index);

    const checkboxClicked = isEventFromModusCheckbox(mouseEvent);
    if (mouseEvent.ctrlKey || checkboxClicked) return this.toggleScanSelect(rowData.scan);

    this.toggleSingleSelected(rowData.scan);
  }

  onChipClick(event: Event, rowData: RowData) {
    event.stopPropagation();
    this.chipClick.emit(rowData.scan);
  }

  onSortChange(sort: Sort) {
    const sortInfo = this.sortInfo();
    const sortChanged =
      sortInfo.sortBy !== sort.active || sortInfo.sortDirection !== sort.direction;
    if (!sortChanged) return;

    const changedSortInfo: SortInfo = {
      sortBy: <keyof ScandataModel>sort.active,
      sortDirection: sort.direction,
    };

    this.sortInfoChange.emit(changedSortInfo);
  }

  private getThumbnail(scan: ScandataModel) {
    if (scan.pointcloudStatus !== PointcloudStatus.Ready) return Thumbnail.Busy;

    const hasPreview = scan.thumbnailUrl?.length ?? 0 > 0;
    if (hasPreview) return Thumbnail.Preview;

    const hasStation = scan.numberOfStations > 0;
    if (hasStation) return Thumbnail.Station;

    return Thumbnail.None;
  }

  private getIcon(scan: ScandataModel) {
    switch (scan.pointcloudStatus) {
      case PointcloudStatus.Failed:
        return { icon: 'alert', color: 'text-red', message: 'Failed' };
      case PointcloudStatus.Ready:
        return { icon: 'check_circle', color: 'text-green', message: 'Ready' };
      case PointcloudStatus.Processing:
        return { icon: 'more_circle', color: 'text-trimble-yellow', message: 'Processing' };
      case PointcloudStatus.Uploading:
        return { icon: 'cloud_upload', color: 'text-gray-4', message: 'Uploading' };
      default:
        return { icon: '', color: 'text-trimble-gray', message: '' };
    }
  }

  private toggleScanSelect(scan: ScandataModel) {
    scan.selected = !scan.selected;
    this.selectionChange.emit([scan]);
  }

  private toggleRangeSelected(scan: ScandataModel, selectedIndex: number) {
    const data = this.data();
    if (data.length === 0) return;

    const lastSelectedId = this.lastSelectedId() ?? data[0].scan.id;
    if (isNil(lastSelectedId)) return;

    const prevSelectedIndex = data.findIndex((row, index) => {
      return row.scan.id === lastSelectedId ? index : null;
    });

    const startIndex = selectedIndex < prevSelectedIndex ? selectedIndex : prevSelectedIndex;
    const endIndex = selectedIndex > prevSelectedIndex ? selectedIndex : prevSelectedIndex;

    const selection = data.filter((_, index) => index >= startIndex && index <= endIndex);
    if (selectedIndex < prevSelectedIndex) selection.reverse();

    const selected = !scan.selected;
    const changedScans = selection.map((row) => <ScandataModel>{ ...row.scan, selected });

    this.selectionChange.emit(changedScans);
  }

  private toggleSingleSelected(scan: ScandataModel) {
    const currentSelection = this.data().filter((row) => row.scan.selected);
    const inCurrentSelection = currentSelection.find((row) => row.scan === scan);

    currentSelection.forEach((row) => (row.scan.selected = false));

    //unselect scan if it's the only current selection
    scan.selected = currentSelection.length === 1 && inCurrentSelection ? false : true;

    const changedScans = currentSelection.map((row) => row.scan);
    if (!inCurrentSelection) changedScans.push(scan);

    this.selectionChange.emit(changedScans);
  }

  private createSortInfoEffect() {
    effect(() => {
      const sortInfo = this.sortInfo();
      const sort = this.sort();

      if (sort.active === sortInfo.sortBy && sort.direction === sortInfo.sortDirection) return;

      sort.sort({
        id: sortInfo.sortBy,
        start: sortInfo.sortDirection,
        disableClear: true,
      });
    });
  }

  private subscribeTagsColumnResizeObserver() {
    /**
     * Due to the lack of paging, there could potentially be 100's chip-container components
     * within this component. To avoid the performance overhead of each chip-container
     * registering it's own resize observer, we are registering just one here and emitting
     * on a signal that the chip-container's parent element size has changed.
     */
    getResizeObservable(this.tagsColumnHeader().nativeElement)
      .pipe(
        debounceTime(200),
        map((observation) => {
          const entry = observation.entries[0];
          const { inlineSize } = entry.contentBoxSize[0];
          return { height: this.chipContainerHeight, width: inlineSize } as ChipContainerSize;
        }),
        shareReplay({ refCount: true, bufferSize: 1 }),
      )
      .subscribe({
        next: (size) => {
          const count = this.getOffscreentagCount(size);
          this.tagsContainerSize.set(size);
          this.offscreenTagCount.set(count);
        },
      });
  }

  private getOffscreentagCount(containerSize: ChipContainerSize) {
    const { height, width } = containerSize;
    const rows = Math.floor(height / CHIP_HEIGHT);
    const cols = Math.ceil(width / CHIP_MIN_WIDTH);
    return rows * cols;
  }
}

const isEventFromModusCheckbox = (value: Event): boolean => {
  const src = value.target;
  return isDefined(src) && 'tagName' in src && src.tagName === 'MODUS-CHECKBOX';
};
