import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { isDefined, isNil } from '@trimble-gcs/common';
import { EMPTY, Observable, filter, forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';
import { ViewSpec } from 'trimble-connect-workspace-api';
import { Scan3dService } from '../scan-3d-panel/scan-3d.service';
import { PatchScandataModel } from '../scandata/scandata.actions';
import { ScandataDisplayStatus } from '../scandata/scandata.models';
import { ScandataState } from '../scandata/scandata.state';
import { SettingService } from '../setting/setting.service';
import { ClearCurrentStation } from '../station/station.actions';
import { Station } from '../station/station.models';
import { StationService } from '../station/station.service';
import { StationState } from '../station/station.state';
import { ConnectView } from './host-3d.model';
import { Host3dService } from './host-3d.service';

@Injectable({
  providedIn: 'root',
})
export class Host3dViewService {
  private settingPreFix = 'view_';

  constructor(
    private settingService: SettingService,
    private host3dService: Host3dService,
    private scan3dService: Scan3dService,
    private stationService: StationService,
    private store: Store,
  ) {}

  createView(view: ViewSpec) {
    if (isNil(view.id)) return EMPTY;

    const key = this.settingPreFix + view.id;
    return this.createConnectView(key).pipe(
      switchMap((connectView) => this.settingService.putSetting<ConnectView>(key, connectView)),
    );
  }

  updateView(view: ViewSpec) {
    if (isNil(view.id)) return EMPTY;

    const key = this.settingPreFix + view.id;
    return this.createConnectView(key).pipe(
      switchMap((connectView) => this.settingService.putSetting<ConnectView>(key, connectView)),
    );
  }

  removeView(view: ViewSpec) {
    if (isNil(view.id)) return EMPTY;

    const key = this.settingPreFix + view.id;
    return this.settingService.deleteSetting(key);
  }

  setView(viewSpec: ViewSpec) {
    if (isNil(viewSpec.id)) return EMPTY;

    const key = this.settingPreFix + viewSpec.id;
    return this.settingService.getSetting<ConnectView>(key).pipe(
      map((setting) => setting.data),
      switchMap((view) => this.hideDisplayedScans(view).pipe(map(() => view))),
      filter((view) => this.hasCurrentStationOrScans(view)),
      switchMap((view) => this.showViewScans(view).pipe(map(() => view))),
      switchMap((view) => this.styleViewScans(view).pipe(map(() => view))),
      switchMap((view) => this.setViewCurrentStation(view)),
    );
  }

  private createConnectView(id: string): Observable<ConnectView> {
    const displayedScans$ = this.store
      .selectOnce(ScandataState.scandata)
      .pipe(
        map((scans) => scans.filter((x) => x.displayStatus === ScandataDisplayStatus.Displayed)),
      );

    const scansWithStyle$ = displayedScans$.pipe(
      withLatestFrom(this.scan3dService.getGlobalStyle()),
      map(([scans, globalStyle]) =>
        scans.map((scan) => ({ ...scan, scan3dStyle: scan.scan3dStyle ?? globalStyle })),
      ),
    );

    return scansWithStyle$.pipe(
      withLatestFrom(this.store.select(StationState.currentStation)),
      map(([scans, currentStation]) => ({
        id,
        scans,
        currentStation,
      })),
    );
  }

  private hideDisplayedScans(connectView: ConnectView): Observable<unknown> {
    const scansDisplayed = this.store
      .selectSnapshot(ScandataState.scandata)
      .filter(
        (scan) =>
          scan.displayStatus === ScandataDisplayStatus.AwaitingDisplay ||
          scan.displayStatus === ScandataDisplayStatus.Displayed,
      );

    const scansToHide = scansDisplayed.filter(
      (scan) => connectView.scans.findIndex((x) => x.id === scan.id) === -1,
    );

    return scansToHide.length > 0 ? this.host3dService.hideScans(scansToHide) : of(true);
  }

  private hasCurrentStationOrScans(view: ConnectView) {
    return isDefined(view.currentStation) || this.getStoreScansForView(view).length > 0;
  }

  private getStoreScansForView(connectView: ConnectView) {
    return this.store.selectSnapshot(ScandataState.getScansForConnectView(connectView));
  }

  private showViewScans(connectView: ConnectView): Observable<unknown> {
    const scansToShow = this.getStoreScansForView(connectView).filter(
      (scan) => scan.displayStatus !== ScandataDisplayStatus.Displayed,
    );

    if (scansToShow.length === 0) return of(true);

    const showScans$ = scansToShow.map((scan) => this.host3dService.showScan(scan));

    return forkJoin(showScans$);
  }

  private styleViewScans(connectView: ConnectView): Observable<unknown> {
    const storeScansForView = this.getStoreScansForView(connectView);
    const viewScansInStore = connectView.scans.filter((scan) =>
      storeScansForView.find((x) => x.id === scan.id),
    );

    if (viewScansInStore.length === 0) return of(true);

    const patches = viewScansInStore.map(
      (scan) =>
        new PatchScandataModel({
          id: scan.id,
          scan3dStyle: scan.scan3dStyle,
        }),
    );

    return this.store.dispatch(patches);
  }

  private setViewCurrentStation(connectView: ConnectView): Observable<unknown> {
    if (isNil(connectView.currentStation)) {
      return this.store.dispatch(new ClearCurrentStation());
    }

    // Do nothing if view station is already displaying
    const currentStation = this.store.selectSnapshot(StationState.currentStation);
    if (currentStation?.station.id === connectView.currentStation.station.id) return of(true);

    const pointcloudId = connectView.currentStation.station.pointcloudId;
    return this.stationService.getStations(pointcloudId).pipe(
      map((stations) =>
        stations.find((station) => connectView.currentStation?.station.id === station.id),
      ),
      filter((station): station is Station => isDefined(station)),
      switchMap((station) => this.host3dService.setCurrentStation(station)),
    );
  }
}
