export enum ConnectPanelStatus {
  CheckingStatus = 'CheckingStatus',
  FileSizeExceeded = 'FileSizeExceeded',
  IngestionError = 'IngestionError',
  NotAdmin = 'NotAdmin',
  NoActiveLicense = 'NoActiveLicense',
  QuotaExceeded = 'QuotaExceeded',
  ReadyToTile = 'ReadyToTile',
  ReadyToTileNewVersion = 'ReadyToTileNewVersion',
  ReadyToView = 'ReadyToView',
  ReadyToRefresh = 'ReadyToRefresh',
  Refreshing = 'Refreshing',
  TilingError = 'TilingError',
  Tiling = 'Tiling',
  Viewing = 'Viewing',
}
