export const CLIENT_HEADER_KEYS = [
  'Trimble-FieldSystems-ClientName',
  'Trimble-FieldSystems-ClientVersion',
] as const;

export type ClientHeaders = Record<(typeof CLIENT_HEADER_KEYS)[number], string>;

export enum IngestionSource {
  Connect3D = 'Connect 3D',
  ConnectBrowser = 'Connect Browser',
  Import = 'Import',
}
