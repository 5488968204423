import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  ChangeDetectionStrategy,
  Component,
  effect,
  input,
  output,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { isDefined, isNil } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusCheckboxModule,
  ModusFormFieldModule,
  ModusIconModule,
  ModusSelectModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import {
  ScandataEmptyComponent,
  ScandataEmptyReason,
} from '../../scandata-list/scandata-empty/scandata-empty.component';
import { SortInfo } from '../../scandata/scandata-query.models';
import { PointcloudStatus, ScandataModel } from '../../scandata/scandata.models';
import { MapFilterOption, MapScandataModel } from '../map.models';
import { MapListSortMenuComponent } from './map-list-sort-menu/map-list-sort-menu.component';

interface ScanListItem {
  scan: MapScandataModel;
  notReady: boolean;
  hasError: boolean;
  message: string | null;
}

@Component({
  selector: 'sd-map-list',
  standalone: true,
  imports: [
    CommonModule,
    ScandataEmptyComponent,
    MapListSortMenuComponent,
    MatProgressBarModule,
    ModusFormFieldModule,
    ModusIconModule,
    ModusButtonModule,
    ModusSelectModule,
    ModusCheckboxModule,
    ModusTooltipModule,
    ScrollingModule,
    MatSelectModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './map-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MapListComponent {
  scanLoadError = input<boolean>(false);
  isLoading = input<boolean>(false);
  lastSelectedId = input<string>();

  filterOption = input.required<MapFilterOption>();
  sortInfo = input.required<SortInfo>();
  showScandataEmpty = input.required<boolean>();
  scandataEmptyReason = input.required<ScandataEmptyReason>();

  mapScandata = input.required<ScanListItem[], MapScandataModel[]>({
    transform: (scans) => {
      return scans.map((scan) => ({
        scan,
        notReady: this.scanNotReady(scan),
        hasError: this.scanHasError(scan),
        message: this.getScanMessage(scan),
      }));
    },
  });

  changeItemVisibilityClicked = output<ScandataModel>();
  selectionChange = output<ScandataModel[]>();
  zoomToItemClicked = output<ScandataModel>();
  filterOptionChanged = output<MapFilterOption>();
  sortChanged = output<SortInfo>();

  mapFilterOption = MapFilterOption;
  filterOptionControl = new FormControl<MapFilterOption>(MapFilterOption.All);

  private filterOptionControlValue = toSignal(this.filterOptionControl.valueChanges, {
    initialValue: null,
  });

  constructor() {
    effect(() => {
      this.filterOptionControl.setValue(this.filterOption());
    });

    effect(() => {
      const filterOptionControlValue = this.filterOptionControlValue();
      if (isNil(filterOptionControlValue)) return;
      this.filterOptionChanged.emit(filterOptionControlValue);
    });
  }

  rowClicked(mouseEvent: MouseEvent, scan: MapScandataModel, index: number) {
    if (mouseEvent.shiftKey) return this.toggleRangeSelected(scan, index);

    const checkboxClicked = isEventFromModusCheckbox(mouseEvent);
    if (mouseEvent.ctrlKey || checkboxClicked) return this.toggleScanSelect(scan);

    this.toggleSingleSelected(scan);
  }

  checkboxKeyDown(scan: MapScandataModel) {
    this.toggleScanSelect(scan);
  }

  private scanHasError(scan: ScandataModel) {
    return scan.pointcloudStatus === PointcloudStatus.Failed;
  }

  private scanNotReady(scan: ScandataModel) {
    return (
      scan.pointcloudStatus === PointcloudStatus.Uploading ||
      scan.pointcloudStatus === PointcloudStatus.Processing
    );
  }

  private getScanMessage(scan: ScandataModel) {
    if (this.scanNotReady(scan)) return 'Not ready for viewing';
    if (this.scanHasError(scan)) return 'Failed to ingest';
    return null;
  }

  private toggleScanSelect(scan: MapScandataModel) {
    scan.selected = !scan.selected;
    this.selectionChange.emit([scan]);
  }

  private toggleRangeSelected(scan: MapScandataModel, selectedIndex: number) {
    const data = this.mapScandata();
    if (data.length === 0) return;

    const lastSelectedId = this.lastSelectedId() ?? data[0].scan.id;
    if (isNil(lastSelectedId)) return;

    const prevSelectedIndex = data.findIndex((row, index) => {
      return row.scan.id === lastSelectedId ? index : null;
    });

    const startIndex = selectedIndex < prevSelectedIndex ? selectedIndex : prevSelectedIndex;
    const endIndex = selectedIndex > prevSelectedIndex ? selectedIndex : prevSelectedIndex;

    const selection = data.filter((_, index) => index >= startIndex && index <= endIndex);
    if (selectedIndex < prevSelectedIndex) selection.reverse();

    const selected = !scan.selected;
    const changedScans = selection.map((row) => <ScandataModel>{ ...row.scan, selected });

    this.selectionChange.emit(changedScans);
  }

  private toggleSingleSelected(scan: MapScandataModel) {
    const currentSelection = this.mapScandata().filter((row) => row.scan.selected);
    const inCurrentSelection = currentSelection.find((row) => row.scan === scan);

    currentSelection.forEach((row) => (row.scan.selected = false));

    //unselect scan if it's the only current selection
    scan.selected = currentSelection.length === 1 && inCurrentSelection ? false : true;

    const changedScans = currentSelection.map((row) => row.scan);
    if (!inCurrentSelection) changedScans.push(scan);

    this.selectionChange.emit(changedScans);
  }
}

const isEventFromModusCheckbox = (value: Event): boolean => {
  const src = value.target;
  return isDefined(src) && 'tagName' in src && src.tagName === 'MODUS-CHECKBOX';
};
