import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { switchMap } from 'rxjs';
import { GET_CONNECT_REGION_URL } from './get-connect-region-url';
import { ConnectFileDetail } from './models/connect-file-detail';

@Injectable({
  providedIn: 'root',
})
export class ConnectFileService {
  private readonly getConnectRegionUrl$ = inject(GET_CONNECT_REGION_URL);

  constructor(private http: HttpClient) {}

  public getFileVersions(fileId: string) {
    return this.getConnectRegionUrl$(`files/${fileId}/versions`).pipe(
      switchMap((url) => this.http.get<ConnectFileDetail[]>(url)),
    );
  }
}
