import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
  OnDestroy,
  untracked,
  viewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSort, MatSortModule, SortDirection } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isDefined, isNil, isNumeric } from '@trimble-gcs/common';
import {
  ModusButtonModule,
  ModusIconModule,
  ModusInputModule,
  ModusTooltipModule,
} from '@trimble-gcs/modus';
import { ColorPickerModule } from 'ngx-color-picker';
import { filter } from 'rxjs';
import {
  ClassificationScheme,
  DEFAULT_CLASSIFICATION_COLOR,
} from '../../../classification/classification-scheme.model';
import { DialogComponent } from '../../../dialog/dialog.component';
import { CANCEL_BUTTON, DialogData } from '../../../dialog/dialog.model';
import { DialogService } from '../../../dialog/dialog.service';
import { colorHexStripAlpha } from '../../../utils/color-converter';
import { NotWhitespaceStringValidator } from '../../../utils/not-whitespace-string-validator';
import { UniqueValueValidator } from '../../../utils/unique-value-validator';
import { ClassificationFormState } from '../classification-form-state';

type ClassificationForm = FormGroup<{
  id: FormControl<number | null>;
  name: FormControl<string>;
  rgba: FormControl<string>;
  visible: FormControl<boolean>;
  predefined: FormControl<boolean>;
  isNew: FormControl<boolean>;
}>;

@UntilDestroy()
@Component({
  selector: 'sd-config-classification-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ModusInputModule,
    ModusIconModule,
    ModusButtonModule,
    ModusTooltipModule,
    MatTableModule,
    MatSortModule,
    ColorPickerModule,
  ],
  templateUrl: './classification-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassificationTableComponent implements OnDestroy {
  classifications = input.required<ClassificationScheme[]>();
  disabled = input.required<boolean>();

  showAdd = computed(() => isDefined(this.classifications()));

  stripAlpha = colorHexStripAlpha;

  private readonly sort = viewChild.required(MatSort);
  private readonly form = new FormArray<ClassificationForm>([]);

  readonly dataSource = this.getDataSource();
  readonly displayedColumns = ['id', 'name', 'rgba', 'visible', 'delete'];

  readonly minClassificationId = 0;
  readonly maxClassificationId = 255;

  private deleteDialogRef: MatDialogRef<DialogComponent> | null = null;

  constructor(
    private dialogService: DialogService,
    private state: ClassificationFormState,
  ) {
    this.createClassificationEffect();
    this.createDisabledEffect();
    this.createMatSortEffect();
    this.subscribeToFormValueChanges();
  }

  ngOnDestroy(): void {
    this.deleteDialogRef?.close();
  }

  setColor(color: string, classificationForm: ClassificationForm) {
    const rgba = color.slice(1).toUpperCase();
    const rawValue = classificationForm.getRawValue();

    const valueChanged = rgba !== rawValue.rgba;
    if (!valueChanged) return;

    const newValue = { ...rawValue, ...{ rgba } };
    classificationForm.markAsDirty();
    classificationForm.setValue(newValue);
  }

  toggleVisibility(classificationForm: ClassificationForm) {
    const rawValue = classificationForm.getRawValue();
    const value = { ...rawValue, ...{ visible: !rawValue.visible } };
    classificationForm.markAsDirty();
    classificationForm.setValue(value);
  }

  addNewClassifcation() {
    const addNewClassificationForm = this.createClassificationForm({
      predefined: false,
      isNew: true,
    });
    this.form.markAsDirty();
    this.form.push(addNewClassificationForm);
    this.dataSource.data = this.form.controls;
  }

  deleteClassification(classificationForm: ClassificationForm) {
    this.showDeleteConfirmation(classificationForm).subscribe(() => {
      const index = this.form.controls.indexOf(classificationForm);
      if (index < 0) return;

      this.form.markAsDirty();
      this.form.removeAt(index);
      this.dataSource.data = this.form.controls;

      this.validateUniqueIds();
    });
  }

  validateUniqueIds() {
    this.form.controls.forEach((classificationForm) => {
      if (classificationForm.value.predefined) return;
      if (classificationForm.controls.id.valid) return;
      classificationForm.controls.id.updateValueAndValidity();
    });
  }

  trimInputValue(formControl: FormControl<string>) {
    formControl.setValue(formControl.value.trim());
  }

  private getDataSource() {
    const dataSource = new MatTableDataSource<ClassificationForm>();

    dataSource.sortData = (data, sort) => {
      if (sort.direction === '') return data;

      // Sort only saved classifications. The classifcations create with the add new button
      // must stay at the bottom of the list in their original order.
      const classificationsToSort = data.filter(
        (classificationForm) => !classificationForm.value.isNew,
      );

      const sortedClassifications = this.sortData(
        classificationsToSort,
        sort.active,
        sort.direction,
      );

      const newClassifications = data.filter(
        (classificationForm) => classificationForm.value.isNew,
      );

      return [...sortedClassifications, ...newClassifications];
    };

    return dataSource;
  }

  private createClassificationEffect() {
    effect(() => {
      const classifications = this.classifications();
      this.initForm(classifications);
    });
  }

  private createDisabledEffect() {
    effect(() => {
      this.disabled() ? this.form.disable() : this.form.enable();
    });
  }

  private createMatSortEffect() {
    effect(() => {
      this.dataSource.sort = this.sort();
    });
  }

  private initForm(classifications: ClassificationScheme[]) {
    // untracked(), because setting the dataSource causes an
    // internal signal set somewhere
    untracked(() => {
      this.form.clear();
      this.form.reset();

      if (isDefined(classifications)) {
        classifications.forEach((classificationScheme) => {
          const classificationForm = this.createClassificationForm(classificationScheme);
          this.form.push(classificationForm, { emitEvent: false });
        });

        this.dataSource.data = this.form.controls;
      }
    });
  }

  private createClassificationForm(
    classificationScheme: Partial<ClassificationScheme>,
  ): ClassificationForm {
    const classificationForm = new FormGroup({
      id: new FormControl<number | null>(classificationScheme.id ?? null, {
        validators: [
          Validators.required,
          Validators.min(this.minClassificationId),
          Validators.max(this.maxClassificationId),
          UniqueValueValidator((control) => this.isIdUnique(control)),
        ],
      }),
      name: new FormControl<string>(classificationScheme.name ?? '', {
        nonNullable: true,
        validators: [Validators.required, NotWhitespaceStringValidator],
      }),
      rgba: new FormControl<string>(classificationScheme.rgba ?? DEFAULT_CLASSIFICATION_COLOR, {
        nonNullable: true,
        validators: [Validators.required],
      }),
      visible: new FormControl<boolean>(classificationScheme.visible ?? true, {
        nonNullable: true,
      }),
      predefined: new FormControl<boolean>(classificationScheme.predefined ?? false, {
        nonNullable: true,
      }),
      isNew: new FormControl<boolean>(classificationScheme.isNew ?? false, {
        nonNullable: true,
      }),
    });

    return classificationForm;
  }

  private subscribeToFormValueChanges() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.state.setValid(this.form.valid);
      this.state.setDirty(this.form.dirty);

      if (this.form.pristine || this.form.invalid) return;

      const data = this.form.getRawValue().map((classificationScheme) => {
        const scheme: ClassificationScheme = {
          id: classificationScheme.id as number,
          name: classificationScheme.name,
          rgba: classificationScheme.rgba,
          visible: classificationScheme.visible,
          predefined: classificationScheme.predefined,
        };
        return scheme;
      });

      this.state.setValue(data);
    });
  }

  private isIdUnique(formControl: AbstractControl): boolean {
    const rawValue = formControl.value;
    if (isNil(rawValue)) return true;

    const value = parseInt(rawValue);

    const isUnique = this.form.controls
      .filter((classificationForm) => classificationForm.controls.id !== formControl)
      .every((classificationForm) => classificationForm.value.id !== value);

    return isUnique;
  }

  private sortData(
    data: ClassificationForm[],
    sortByProperty: string,
    sortDirection: SortDirection,
  ) {
    const sortedData = data.sort((a, b) => {
      const propertyKey = sortByProperty as keyof typeof a.value;

      const valueA = a.value[propertyKey]?.toString() ?? '';
      const valueB = b.value[propertyKey]?.toString() ?? '';

      if (valueA === valueB) return 0;

      const compareValueA = isNumeric(valueA) ? Number(valueA) : valueA.toLowerCase();
      const compareValueB = isNumeric(valueB) ? Number(valueB) : valueB.toLowerCase();

      if (sortDirection === 'asc') {
        return compareValueA < compareValueB ? -1 : 1;
      } else {
        return compareValueA > compareValueB ? -1 : 1;
      }
    });

    return sortedData;
  }

  private showDeleteConfirmation(classificationForm: ClassificationForm) {
    const id = classificationForm.value.id;
    const name = classificationForm.value.name;
    const description = [
      isDefined(id) ? `#${id}` : null,
      isDefined(name) && name.trim().length > 0 ? name : null,
    ]
      .filter((value) => isDefined(value))
      .join(' ');

    const message =
      description.length > 0
        ? `Are you sure you want to delete the classification '${description}'?`
        : `Are you sure you want to delete this classification?`;

    const dialogData = new DialogData(
      'Delete Classification',
      message,
      { text: 'Delete', color: 'danger' },
      CANCEL_BUTTON,
    );

    this.deleteDialogRef = this.dialogService.showMessage(dialogData);

    return this.deleteDialogRef.afterClosed().pipe(filter((confirmed) => confirmed ?? false));
  }
}
